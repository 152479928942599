import { IState } from "./booking.types";
import IAction from "../../interfaces/IAction";
import * as CONSTANTS from "../booking/booking.constants";

export const initialState: IState = {
  otpSent: false,
  phone: '',
  loading: false,
  bookings: [],
  tickets: [],
  verified: false,
  currentUser: null,
  monthDates: {},
  specialMonthDates:{},
  checkoutId: '',
  selectedDate: '',
  userBookings: [],
  userBillings: [],
  sessionId:'',
  type: '',
  couponData: null
}

export default (state: IState = initialState, action: IAction): IState => {
  switch (action.type) {
    case CONSTANTS.TICKET_LIST_REQUEST:
    case CONSTANTS.VERIFY_OTP_REQUEST:
    case CONSTANTS.CHECKOUT_ID_REQUEST:
    case CONSTANTS.ADD_BOOKING_REQUEST:
    case CONSTANTS.SEND_OTP_REQUEST:
    case CONSTANTS.START_LOADING:
      return { ...state, loading: true }

    case CONSTANTS.ADD_BOOKING_FAILURE:
    case CONSTANTS.CHECKOUT_ID_FAILURE:
    case CONSTANTS.VERIFY_OTP_FAILURE:
    case CONSTANTS.TICKET_LIST_FAILURE:
    case CONSTANTS.SEND_OTP_FAILURE:
      return { ...state, loading: false }
    case CONSTANTS.STOP_LOADING:
      return { ...state, loading: false }
    case CONSTANTS.SEND_OTP_SUCCESS:
      //@ts-ignore
      return { ...state, loading: false, otpSent: true, phone: action.payload?.phone }

    case CONSTANTS.SAVE_BOOKING_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        //@ts-ignore
        bookings: action.payload?.bookings, selectedDate: action.payload?.selectedDate,
        verified: false
      }

    case CONSTANTS.VERIFY_OTP_SUCCESS:
      //@ts-ignore
      return { ...state, loading: false, verified: action.payload?.verified, currentUser: action.payload?.currentUser }

    case CONSTANTS.UPDATE_PROFILE_SUCCESS:
      return {
        ...state, loading: false,
        //@ts-ignore
        currentUser: {
          ...state.currentUser,
          //@ts-ignore
          user: action.payload.user
        }
      }

    case CONSTANTS.TICKET_LIST_SUCCESS:
      //@ts-ignore
      return { ...state, loading: false, tickets: action.payload?.tickets }

    case CONSTANTS.GET_CALENDER_SUCCESS:
      //@ts-ignore
      return { ...state, loading: false, monthDates: action.payload?.monthDates, specialMonthDates: action.payload?.specialMonthDates }

    case CONSTANTS.CHECKOUT_ID_SUCCESS:
      //@ts-ignore
      return { ...state, loading: false, checkoutId: action.payload?.checkoutId, type: action.payload?.type,
        //@ts-ignore
        sessionId: action.payload?.sessionId ?  action.payload?.sessionId : state.sessionId
      }
    case CONSTANTS.BOOKING_LIST_SUCCESS:
      //@ts-ignore
      return { ...state, loading: false, userBookings: action.payload?.bookings }

    case CONSTANTS.BILLING_LIST_SUCCESS:
      //@ts-ignore
      return { ...state, loading: false, userBillings: action.payload?.billings }

    case CONSTANTS.CALCULATE_COUPON_SUCCESS:
      //@ts-ignore
      return { ...state, loading: false, couponData: action.payload?.couponData }

    case CONSTANTS.REMOVE_COUPON_REQUEST:
      //@ts-ignore
      return { ...state, loading: false, couponData: null }

    case CONSTANTS.RESET_BOOKING_DATA:
      return {
        ...state,
        loading: false,
        otpSent: false,
        verified: false,
        checkoutId: '',
        bookings: [],
        selectedDate: '',
        sessionId:'',
        couponData: null
      }
    case CONSTANTS.CHANGE_PHONE:
      return {
        ...state,
        loading: false,
        otpSent: false,
        verified: false,
        currentUser: null,
        userBookings: [],
        userBillings: []
      }
    case CONSTANTS.RESET_ALL:
      return { ...initialState }
    default:
      return state
  }
}
