import React, { ReactElement, useEffect, useState } from 'react'
import { Button, Card, Col, Form, Image, Input, Layout, Radio, Row, Table } from "antd";
import { useReduxDispatch } from "../../../helpers";
import { useSelector } from "react-redux";
import { getBookings, getCurrentUser, getLoading, getSelectedDate, getUserBookings } from "../booking.selectors";
import { ColumnsType } from "antd/es/table";
import { Booking, BookingDetails, BookingInfo } from "../booking.types";
import HeaderSteps from "../../../components/steps";
import { getBookingList, getCheckoutID } from "../booking.actions";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import FooterCom from './../../../components/Footer'
import AppHeader from "../../../components/Header";
import moment from "moment";

const { Header, Footer, Content } = Layout;

export default function MyTicketsContainer(): ReactElement {
  const dispatch = useReduxDispatch()
  const navigate = useNavigate()
  const loading = useSelector(getLoading)
  const bookings = useSelector(getUserBookings)
  const currentUser = useSelector(getCurrentUser)
  const selectedDate = useSelector(getSelectedDate)
  const language = localStorage.getItem("i18nextLng") || "en";
  const [bookingDetails, setBookingDetails] = useState<BookingDetails[]>([])
  const { t } = useTranslation();


  const onGoBack = () => {
    navigate('/')
  }
  useEffect(()=>{
    //@ts-ignore
    dispatch(getBookingList(currentUser?.access_token))
  },[])

  useEffect(()=>{
    const details: React.SetStateAction<BookingDetails[]> | BookingDetails[] = []
    bookings.forEach((booking)=>{
      if(booking.ticket_booking_details){
        details.push(...booking.ticket_booking_details)
      }
    })
    setBookingDetails(details)
  },[bookings])

  const onPurchase = async () => {

  }

  const columns: ColumnsType<BookingDetails> = [
    {
      title: t('date'),
      dataIndex: 'created_at',
      key: 'created_at',
      render: (text, item) => <span>{moment(text).format('YYYY-MM-DD hh:mm')}</span>,
    },
    {
      title: t('type'),
      dataIndex: 'name',
      key: 'name',
      render: (text, item) => <span>{language === 'en' ? item.ticket.name : item.ticket.name_arabic}</span>,
    },
    {
      title: t('view'),
      dataIndex: 'ticket_url',
      key: 'ticket_url',
      render: (text, item) => <a style={{color:'white'}} target={'_blank'} href={text}>{t('view_ticket')}</a>,
    }
  ]

  return (
    <Layout>
      <AppHeader/>
      <Content className="body-container">
        <div className={'app-container'}>
          <Row justify={'center'}>
            {/*<Col span={21}>
              <HeaderSteps current={2}/>
            </Col>*/}
            <Col xs={22} md={18} lg={14}>
              <Card
                type="inner"
                className="mt-50 pb-20"
                style={{ minHeight: 300 }}>
                <Row align={'middle'} justify={'center'} className={'mt-20'}>
                  <Col span={22} className={'mt-30'}>
                    <Table
                      size={'small'}
                      columns={columns}
                      dataSource={bookingDetails}
                      pagination={false}
                    />
                  </Col>
                  <Col span={22}>
                    <div className={'card-body no-padding  mt-30'}>
                      <Button
                        style={{width: '94%', marginLeft: '3%', marginRight: '3%', backgroundColor:'#FFC42F'}}
                        block
                        className="book-now mt-30 mb-20"
                        size={"large"}
                        htmlType="submit"
                        onClick={onGoBack}
                        type="primary">{t('go_back')}</Button>
                    </div>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </div>
      </Content>
      <Footer className="footer-container" dir={'ltr'}>
        <FooterCom/>
      </Footer>
    </Layout>
  )
}
