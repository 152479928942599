import React, { ReactElement, useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Image,
  Input,
  Layout,
  message,
  Modal,
  Radio,
  Row,
  Select,
  Spin,
  Statistic,
} from "antd";
import { useReduxDispatch } from "../../../helpers";
import { useSelector } from "react-redux";
import {
  getAppliedCoupon,
  getBookings,
  getCheckoutId,
  getCurrentUser,
  getLoading,
  getPhone,
  getSelectedDate,
} from "../booking.selectors";
// @ts-ignore
import { Helmet } from "react-helmet";
import { getCheckoutID, updateProfile } from "../booking.actions";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import HeaderSteps from "../../../components/steps";
import FooterCom from "./../../../components/Footer";
import AppHeader from "../../../components/Header";
import { BookingDetails, BookingInfo, BookingParams } from "../booking.types";
import TabbyPromo from "../../../components/TabbyPromo";
import TamaraPromo from "../../../components/TamaraPromo";

const { Countdown } = Statistic;

const { Option } = Select;

const { Header, Footer, Content } = Layout;

export default function PaymentContainer(props: any): ReactElement {
  const dispatch: any = useReduxDispatch();
  const navigate = useNavigate();
  const loading = useSelector(getLoading);
  const bookings = useSelector(getBookings);
  const currentUser: any = useSelector(getCurrentUser);
  const selectedDate = useSelector(getSelectedDate);
  const couponData = useSelector(getAppliedCoupon);
  const { type } = useParams();
  const [tabbyPromo, setTabbyPromo] = useState(false);
  const [checkoutLoading, setCheckoutLoading] = useState(false);
  const [tamaraPromo, setTamaraPromo] = useState(false);
  const [checkoutId, setCheckoutId] = useState("");
  const [checkoutType, setCheckoutType] = useState(type);
  const deadline = Date.now() + 1000 * 30;
  const [showTimer, setShowTimer] = useState(false);
  const { t } = useTranslation();

  const language = localStorage.getItem("i18nextLng") || "en";
  const isArabic = language === "ar";

  useEffect(() => {
    if (!selectedDate) {
      navigate(`/`);
    }
  }, [selectedDate]);

  useEffect(() => {
    if (checkoutId) {
      const script = document.createElement("script");

      script.src = `https://eu-prod.oppwa.com/v1/paymentWidgets.js?checkoutId=${checkoutId}`;
      script.async = true;

      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
  }, [checkoutId]);
  useEffect(() => {
    const script = document.createElement("script");
    script.src = `http://checkout.tabby.ai/tabby-promo.js`;
    script.async = true;

    let total = bookings.reduce((accumulator: any, object: BookingInfo) => {
      return accumulator + object.price * object.quantity;
    }, 0);

    document.body.appendChild(script);
    //@ts-ignore
    new TabbyCard({
      selector: "#TabbyPromo", // empty div for TabbyCard.
      currency: "SAR", // required, currency of your product. AED|SAR|KWD|BHD|QAR only supported, with no spaces or lowercase.
      lang: language, // Optional, language of snippet and popups.
      price: total, // required, total price or the cart. 2 decimals max for AED|SAR|QAR and 3 decimals max for KWD|BHD.
      size: "wide", // required, can be also 'wide', depending on the width.
      theme: "black", // required, can be also 'default'.
      header: false, // if a Payment method name present already.
      installmentsCount: 4,
      source: "product",
    });
  }, []);

  let total = bookings.reduce((accumulator: any, object: BookingInfo) => {
    return accumulator + object.price * object.quantity;
  }, 0);

  useEffect(() => {
    //@ts-ignore
    window.tamaraWidgetConfig = {
      lang: isArabic ? "ar" : "en",
      country: "SA",
      publicKey: "860b265a-fe9b-4d05-8f8b-b2ed4e17c9c2",
    };
  }, []);

  const handleChange = (value: string) => {
    if (checkoutId) {
      navigate(`/checkout/${value.toLowerCase()}`);
      window.location.reload();
    } else {
      setCheckoutType(value.toLowerCase());
    }
  };

  const createCheckout = async () => {
    let sum = bookings.reduce((accumulator: any, object: BookingInfo) => {
      return accumulator + object.price * object.quantity;
    }, 0);
    const tickets = bookings.reduce((accumulator: any, object: BookingInfo) => {
      return accumulator + object.quantity;
    }, 0);
    const data: BookingParams = {
      sub_total: sum,
      total_tickets: tickets,
      selected_date: selectedDate,
      payment_id: checkoutId,
      line_items: [],
    } as any;

    bookings.forEach((booking) => {
      data.line_items.push({
        //@ts-ignore
        ticket_id: booking.id + "",
        quantity: booking.quantity,
      });
    });
    if (couponData && couponData.success) {
      //@ts-ignore
      data.coupon_code = couponData.coupon_code;
      sum = couponData.totalAfterDiscounts;
    }
    const _type =
      checkoutType === "mada"
        ? "MADA"
        : checkoutType === "visa"
        ? "VISA"
        : checkoutType === "tabby"
        ? "tabby"
        : "APPLEPAY";
    //@ts-ignore
    dispatch(getCheckoutID(sum, currentUser?.access_token, _type, data))
      .then((checkoutId: any) => {
        // @ts-ignore
        setCheckoutId(checkoutId);
        //setShowTimer(true)
      })
      .catch((error: string) => {
        message.error(error);
        if (error.includes("incomplete")) navigate("/profile");
      });
  };
  const createTabbyCheckout = async (type: string) => {
    let sum = bookings.reduce((accumulator: any, object: BookingInfo) => {
      return accumulator + object.price * object.quantity;
    }, 0);
    const tickets = bookings.reduce((accumulator: any, object: BookingInfo) => {
      return accumulator + object.quantity;
    }, 0);
    const data: BookingParams = {
      sub_total: sum,
      total_tickets: tickets,
      selected_date: selectedDate,
      payment_id: checkoutId,
      line_items: [],
    } as any;
    bookings.forEach((booking) => {
      data.line_items.push({
        //@ts-ignore
        ticket_id: booking.id + "",
        quantity: booking.quantity,
      });
    });
    if (couponData && couponData.success) {
      //@ts-ignore
      data.coupon_code = couponData.coupon_code;
      sum = couponData.totalAfterDiscounts;
    }
    setCheckoutLoading(true);
    //@ts-ignore
    dispatch(
      getCheckoutID(
        sum,
        currentUser?.access_token,
        type,
        data,
        `https://tickets.cyanwp.com/success/${type}`,
        isArabic ? "ar" : "en"
      )
    )
      .then((checkoutId: any) => {
        /*console.log('checkoutId', checkoutId)
      checkoutId = checkoutId.replace('locale=ar_SA&', '');
      console.log('checkoutId', checkoutId)
      checkoutId = checkoutId.replace('locale=en_SA&', '');
      console.log('checkoutId', checkoutId)
      checkoutId = checkoutId + '&' + (isArabic ? 'locale=ar_SA' : 'locale=en_SA')
      console.log('checkoutId', checkoutId)*/
        // @ts-ignore
        setCheckoutId(checkoutId);
        setCheckoutLoading(false);
        // @ts-ignore
        window.open(checkoutId, "_self").focus();
        //setShowTimer(true)
      })
      .catch((error: string) => {
        message.error(error);
        if (error.includes("incomplete")) navigate("/profile");
      });
  };

  useEffect(() => {
    if (checkoutType) {
      createCheckout();
    }
  }, [checkoutType]);

  const onTimeFinish = () => {
    /*setShowTimer(false);
    setCheckoutType('');
    window.location.reload()*/
  };

  // @ts-ignore
  return (
    <Layout>
      {/* @ts-ignore */}
      <AppHeader />
      <Content className="body-container" style={{ minHeight: 1400 }}>
        <div className={"app-container"}>
          <Row justify={"center"}>
            <Col span={21}>
              <HeaderSteps current={2} />
            </Col>
            <Col xs={22} md={18} lg={14}>
              <Card
                title={t("payment_information")}
                type="inner"
                className="mt-50 pb-20"
                style={{ minHeight: 300 }}
              >
                <Row align={"middle"} justify={"center"} className={"mt-20"}>
                  <Col span={24}>
                    <Row
                      align={"middle"}
                      justify={"center"}
                      className={"mt-20 mb-30"}
                    >
                      {/*<Col>
                        <h4 style={{ marginBottom: 0, marginRight: 20, marginLeft: 20 }}>Payment Method</h4>
                      </Col>
                      <Col>
                        <Select
                          placeholder={t('select_payment_method')}
                          value={type ? type.toUpperCase() : undefined}
                          defaultValue={null}
                          style={{ width: 160, marginRight: 20, marginLeft: 20 }}
                          onChange={handleChange}>
                          <Option value="APPLEPAY">APPLE PAY</Option>
                          <Option value="MADA">MADA</Option>
                          <Option value="VISA">VISA/MASTER CARD</Option>
                        </Select>
                      </Col>*/}
                      <Col xs={24}>
                        <div className="d-flex justify-content-between align-items-center">
                          <h4
                            style={{
                              marginBottom: 0,
                              marginRight: 20,
                              marginLeft: 20,
                            }}
                          >
                            Payment Method
                          </h4>
                          {/*{showTimer && <Countdown title="" value={deadline} onFinish={onTimeFinish} />}*/}
                        </div>
                      </Col>
                      {(checkoutType && checkoutType === "mada") ||
                      checkoutType === "visa" ? null : (
                        <Col xs={12} md={8}>
                          <a
                            href={selectedDate ? `/checkout/applepay` : "/"}
                            target={"_blank"}
                          >
                            <Button
                              type="text"
                              className={`payment-option ${
                                checkoutType === "applepay"
                                  ? "payment-option-selected"
                                  : ""
                              }`}
                            >
                              <img
                                src={
                                  require("../../../assets/applepay.svg")
                                    .default
                                }
                              />
                            </Button>
                          </a>
                        </Col>
                      )}
                      {(checkoutType && checkoutType === "applepay") ||
                      checkoutType === "visa" ? null : (
                        <Col xs={12} md={8}>
                          <a
                            href={selectedDate ? `/checkout/mada` : ""}
                            target={"_blank"}
                          >
                            <Button
                              type="text"
                              className={`payment-option ${
                                checkoutType === "mada"
                                  ? "payment-option-selected"
                                  : ""
                              }`}
                            >
                              <img
                                src={
                                  require("../../../assets/mada.svg").default
                                }
                              />
                            </Button>
                          </a>
                        </Col>
                      )}
                      {(checkoutType && checkoutType === "applepay") ||
                      checkoutType === "mada" ? null : (
                        <Col xs={24} md={8}>
                          <a
                            href={selectedDate ? `/checkout/visa` : ""}
                            target={"_blank"}
                          >
                            <Button
                              type="text"
                              className={`payment-option ${
                                checkoutType === "visa"
                                  ? "payment-option-selected"
                                  : ""
                              }`}
                            >
                              <img
                                src={
                                  require("../../../assets/visa.svg").default
                                }
                              />
                              <img
                                src={
                                  require("../../../assets/master.svg").default
                                }
                              />
                            </Button>
                          </a>
                        </Col>
                      )}
                    </Row>
                  </Col>
                  <Col xs={24}>
                    <div
                      style={{
                        width: "100%",
                        textAlign: "center",
                        fontWeight: "bold",
                      }}
                    >
                      OR
                    </div>
                  </Col>
                  <Col xs={24}>
                    <Row>
                      <Col
                        xs={12}
                        style={{
                          alignItems: "center",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        {/* <Button
                          onClick={() => {
                            setTamaraPromo(true);
                            setTabbyPromo(false)
                          }}
                          type="text"
                          className={`payment-option ${checkoutType === 'visa' ? 'payment-option-selected' : ''}`}>
                          {isArabic && <img src={require('../../../assets/tamara.svg').default}/>}
                          {!isArabic && <img src="https://cdn.tamara.co/widget-v2/assets/tamara-grad-en.ac5bf912.svg"/>}
                        </Button> */}
                        <div
                          onClick={() => {
                            setTamaraPromo(true);
                            setTabbyPromo(false);
                          }}
                          style={{ padding: 20 }}
                          className={`payment-option ${
                            checkoutType === "visa" || tamaraPromo
                              ? "payment-option-selected"
                              : ""
                          }`}
                        >
                          <TamaraPromo price={total} />
                        </div>
                      </Col>
                      <Col
                        xs={12}
                        style={{
                          alignItems: "center",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          onClick={() => {
                            setTamaraPromo(false);
                            setTabbyPromo(true);
                          }}
                          style={{ padding: 20 }}
                          className={`payment-option ${
                            tabbyPromo ? "payment-option-selected" : ""
                          }`}
                        >
                          <TabbyPromo price={total} />
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={24} style={{ display: "block" }}>
                    <div id="TabbyPromo"></div>
                  </Col>

                  {/*@ts-ignore*/}
                  {/* <Col xs={24} style={{ display: 'block' }}>
                    <div style={{display: "flex", columnGap: 20, marginTop: 20, marginBottom: 20}}>
                      <TamaraPromo price={total}/>
                    </div>
                  </Col> */}

                  {(tabbyPromo || tamaraPromo) && (
                    <Col
                      span={24}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <Button
                        onClick={() => {
                          createTabbyCheckout(tabbyPromo ? "tabby" : "tamara");
                        }}
                        block
                        loading={checkoutLoading}
                        type="primary"
                        className="book-now mt-30"
                        style={{
                          margin: "auto",
                          width: 300,
                          height: 50,
                          marginTop: 50,
                          marginBottom: 20,
                        }}
                      >
                        {isArabic ? "الدفع" : "Checkout"}
                      </Button>
                    </Col>
                  )}
                  <Col span={24}>
                    {checkoutType && !checkoutId && (
                      <div
                        style={{
                          width: "100%",
                          height: 300,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Spin size="large" />
                      </div>
                    )}
                    <div
                      style={{
                        position: "absolute",
                        top: 0,
                        width: "100%",
                        height: "100%",
                        marginBottom: 3000,
                      }}
                    >
                      {checkoutId && type !== "tabby" && (
                        <form
                          action={`https://tickets.cyanwp.com/success/${checkoutType}`}
                          className="paymentWidgets"
                          //@ts-ignore
                          onLoad={() => {
                            //setShowTimer(true)
                          }}
                          data-brands={
                            checkoutType === "mada"
                              ? "MADA"
                              : checkoutType === "visa"
                              ? "VISA MASTER"
                              : "APPLEPAY"
                          }
                        />
                      )}
                      {checkoutId && type === "tabby" && (
                        <iframe src={checkoutId} />
                      )}
                    </div>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </div>
      </Content>
      <Footer className="footer-container" dir={"ltr"}>
        <FooterCom />
      </Footer>
      {/*<Modal
        okText={'Accept'}
        closable={false}
        footer={null}
        cancelButtonProps={{ style: { display: 'none' } }}
        bodyStyle={{ padding: 20, display:'flex', alignItems: 'center', justifyContent: 'center'  }}
        visible={checkoutId !=null && checkoutId !== '' && (checkoutType === 'visa' || checkoutType === 'mada')}>
        {checkoutId &&
          <div>
            <h2>{t('checkout', 'Checkout')}</h2>
          <form
            action={`https://devcyan.web.app/success/${checkoutType || 'visa'}`}
            className="paymentWidgets"
            onLoad={() => {
              console.log('loaded')
            }}
            data-brands={checkoutType === 'mada' ? "MADA" : checkoutType === 'visa' ? "VISA MASTER" : 'APPLEPAY'}/>
          </div>
        }
      </Modal>*/}
    </Layout>
  );
}
