import { createSelector } from "reselect";
import { MODULE_NAME } from './core.constants'
import { ICurrentUser, IState } from "./core.types";

const selectState = (state: { [MODULE_NAME]: IState }): IState => state[MODULE_NAME]

export const getCurrentUser = createSelector(selectState, (state: IState): ICurrentUser => state.currentUser)
export const getSystemStatus = createSelector(selectState, (state: IState): number => state.isLive)
export const getEngTranslation = createSelector(selectState, (state: IState): object => state.enTranslation)
export const getArTranslation = createSelector(selectState, (state: IState): object => state.arTranslation)
export const getTranslationLoading = createSelector(selectState, (state: IState): boolean => state.loading)
