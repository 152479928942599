export const MODULE_NAME = 'core'

export const SET_PROGRESS = `${MODULE_NAME}/SET_PROGRESS`
export const RESET_PROGRESS = `${MODULE_NAME}/RESET_PROGRESS`

export const LOGOUT_REQUEST = `${MODULE_NAME}/LOGOUT_REQUEST`
export const LOGOUT_SUCCESS = `${MODULE_NAME}/LOGOUT_SUCCESS`
export const LOGOUT_FAILURE = `${MODULE_NAME}/LOGOUT_FAILURE`

export const SET_SERVER_ERROR = `${MODULE_NAME}/SET_SERVER_ERROR`
export const RESET_SERVER_ERROR = `${MODULE_NAME}/RESET_SERVER_ERROR`

export const UPLOAD_REQUEST = `${MODULE_NAME}/UPLOAD_REQUEST`
export const UPLOAD_SUCCESS = `${MODULE_NAME}/UPLOAD_SUCCESS`
export const UPLOAD_FAILURE = `${MODULE_NAME}/UPLOAD_FAILURE`

export const DOWNLOAD_REQUEST = `${MODULE_NAME}/DOWNLOAD_REQUEST`
export const DOWNLOAD_SUCCESS = `${MODULE_NAME}/DOWNLOAD_SUCCESS`
export const DOWNLOAD_FAILURE = `${MODULE_NAME}/DOWNLOAD_FAILURE`

export const SYSTEM_STATUS_REQUEST = `${MODULE_NAME}/SYSTEM_STATUS_REQUEST`
export const SYSTEM_STATUS_SUCCESS = `${MODULE_NAME}/SYSTEM_STATUS_SUCCESS`
export const SYSTEM_STATUS_FAILURE = `${MODULE_NAME}/SYSTEM_STATUS_FAILURE`

export const GET_TRANSLATION_REQUEST = `${MODULE_NAME}/GET_TRANSLATION_REQUEST`
export const GET_TRANSLATION_SUCCESS = `${MODULE_NAME}/GET_TRANSLATION_SUCCESS`
export const GET_TRANSLATION_FAILURE = `${MODULE_NAME}/GET_TRANSLATION_FAILURE`

