export const MAIN_WEBSITE_URL = "https://cyanwp.com";
// export const BASE_URL = 'https://cyan.sohoby.com/api'
export const BASE_URL = "https://admin.cyanwp.com/api";
export const getOtpSendUrl = () => `${BASE_URL}/sendOtp`;
export const getOtpVerifyUrl = () => `${BASE_URL}/verify/otp`;
export const getUpdateProfileUrl = () => `${BASE_URL}/user/profile`;
export const getTicketListUrl = () => `${BASE_URL}/tickets/list`;
export const getSystemStatusUrl = () => `${BASE_URL}/live/status`;
export const getCalenderUrl = () => `${BASE_URL}/calendar/limit`;
export const getCheckoutIdUrl = () => `${BASE_URL}/payment/generate/id`;
export const getBookingUrl = () => `${BASE_URL}/ticket/booking/create`;
export const geTranslationUrl = () => `${BASE_URL}/language-label`;
export const getBookingListUrl = () => `${BASE_URL}/ticket/booking`;
export const getBillingListUrl = () => `${BASE_URL}/ticket/booking/invoice`;
export const geLogoutUrl = () => `${BASE_URL}/logout`;
export const getPaymentStatusUrl = (id: string, type: string) =>
  `${BASE_URL}/payment/status/${id}/${type}`;
export const tabbyBookingConfirmUrl = (type: string) =>
  `${BASE_URL}/${type}/callback`;
export const getTabbyPaymentStatusUrl = (type: string) =>
  `${BASE_URL}/${type}/payment/status`;
export const getCalculateCouponUrl = () =>
  `${BASE_URL}/ticket/booking/coupon-calculate`;
export const getReserveCouponUrl = () =>
  `${BASE_URL}/ticket/booking/coupon-reserve`;
export const getReserveTabbyCouponUrl = (type: string) =>
  `${BASE_URL}/${type}/checkout`;
export const getReleaseCouponUrl = () =>
  `${BASE_URL}/ticket/booking/coupon-release`;
