import React from "react";
import { Menu, Button, Image, Layout, Select, Dropdown } from "antd";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { MenuOutlined, RiseOutlined, GlobalOutlined, FileDoneOutlined } from '@ant-design/icons'

import { getCurrentUser } from "../../modules/booking/booking.selectors";
import { useNavigate } from "react-router-dom";
import i18n from "i18next";
import { useReduxDispatch } from "../../helpers";
import { logout } from "../../modules/core/core.actions";
import { resetAllData } from "../../modules/booking/booking.actions";
import { getDeviceCategory, useWindowDimensions } from "../../utils/useWindowDimensions";
import { MAIN_WEBSITE_URL } from "../../constants/urls";

const { Header, Footer, Content } = Layout;
const { Option } = Select;

function AppHeader({ hideTickets = false }) {
  const dispatch = useReduxDispatch()
  const { t } = useTranslation();
  const currentUser = useSelector(getCurrentUser)
  const language = localStorage.getItem("i18nextLng") || "en";
  const navigate = useNavigate()
  
  const { width, height } = useWindowDimensions();
  const isMobile = getDeviceCategory(width) === 'xs'
  const isArabic = language === 'ar'
  const CMS_BASE_URL = `${MAIN_WEBSITE_URL}/${isArabic? 'ar':'en'}`
  
  const MENU_OPTIONS = [
    {
      key: 11,
      label:<a href={`${CMS_BASE_URL}/general-park-rules`} target={'_blank'}>
        <Button
          style={{ marginRight: 0 }}
          icon={<FileDoneOutlined style={{ color: '#00a4e7', fontSize: 18 }}/>}
          type="text">
          {t('general_park_rules')}
        </Button>
      </a>
    },
    {
      key: 1,
      label:
        <Button
          icon={<img
            style={{ height: 30, marginRight: language === 'en' ? -10 : 0, marginLeft: language === 'en' ? -10 : 0 }}
            src={require('./../../assets/dress_icon.svg').default}
            alt='user'/>}
          type="text">
          <span className={'ml-10 mr-3'}>{t('dress_code')}</span>
        </Button>,
      children: [
        { key: 1, label: <a href={`${CMS_BASE_URL}/dress-code-men`} target={'_blank'}>{t('men')}</a> },
        { key: 2, label: <a href={`${CMS_BASE_URL}/dress-code-women`} target={'_blank'}>{t('women')}</a> },
        { key: 3, label: <a href={`${CMS_BASE_URL}/dress-code-kids`} target={'_blank'}>{t('kids')}</a> }
      ],
    },
    /*{
      key: 0,
      label:
        <Button
          icon={<RiseOutlined style={{ color: '#00a4e7' }}/>}
          style={{}}
          type="text">
          <span className={'ml-10 mr-10'}>{t('cyan_rides')}</span>
        </Button>,
      children: [
        {
          key: 1,
          label: <a href={`${CMS_BASE_URL}/rides/family-rides`} target={'_blank'}>{t('family_rides')}</a>
        },
        {
          key: 2,
          label: <a href={`${CMS_BASE_URL}/rides/children-rides`} target={'_blank'}>{t('children_rides')}</a>
        },
        {
          key: 3,
          label: <a href={`${CMS_BASE_URL}/rides/thrill-rides`} target={'_blank'}>{t('thrill_rides')}</a>
        }
      ],
    }*/
  ]
  if (currentUser && currentUser.user) {
    MENU_OPTIONS.push({
      key: 2,
      label: <Button
        type="text"
        onClick={() => {
          navigate('/billing')
        }}
        icon={<img style={{ height: 20 }} src={require('./../../assets/invoice.png')}
                   alt='user'/>}>
        <span className={'ml-10 mr-10'}>{t('billing')}</span>
      </Button>
    })
    MENU_OPTIONS.push({
      key: 3,
      label: <Button
        onClick={() => {
          navigate('/myaccount')
        }}
        type="text"
        icon={<img style={{ height: 20 }}
                   src={require('./../../assets/user_icon.svg').default}
                   alt='user'/>}>
        <span className={'ml-10 mr-10'}> {t('update_profile')}</span>
      </Button>
    })
    MENU_OPTIONS.push({
      key: 4,
      label: <Button
        onClick={async () => {
          await dispatch(logout(currentUser.access_token))
          await dispatch(resetAllData())
          navigate('/')
          window.location.reload();
        }}
        type="text"
        icon={<img style={{ height: 20 }}
                   src={require('./../../assets/logout_icon.svg').default}
                   alt='logout'/>}
      >
        <span className={'ml-10 mr-10'}>{t('signout')}</span>
      </Button>
    })
  }
  MENU_OPTIONS.push({
    key: 5,
    label: <Button
      type="text"
      onClick={() => {
        i18n.changeLanguage(language === 'en' ? 'ar' : 'en').then(() => {
          window.location.reload();
        })
      }}
      icon={<GlobalOutlined style={{ color: '#00a4e7' }}/>}
    >
      <span className={'ml-10 mr-10'}>{language === 'en' ? 'عربي' : 'English'}</span>
    </Button>
  })
  
  const menu = <Menu
    direction={'ltr'}
    items={MENU_OPTIONS}
  />
  return (
    <Header className="header-container" dir={'ltr'}>
      {isMobile && <div className={'header_menu'}>
        <Dropdown overlay={menu} trigger={['click']}>
          <MenuOutlined style={{ color: '#962e48', fontSize: 25, marginRight: 30 }}/>
        </Dropdown>
      </div>}
      {!isMobile &&
      <div className={'header_menu'}>
        <a href={`${CMS_BASE_URL}/general-park-rules`} target={'_blank'}>
          <Button
            style={{ marginRight: 0 }}
            className={'header-button'}
            icon={<FileDoneOutlined style={{ color: '#00a4e7', fontSize: 18 }}/>}
            type="text">
            {t('general_park_rules')}
          </Button>
        </a>
        
       {/* <a href={`${CMS_BASE_URL}/rides`} target={'_blank'}>
          <Dropdown
            overlay={
              <Menu
                direction={'ltr'}
                items={[
                  {
                    key: 1,
                    label: <a href={`${CMS_BASE_URL}/rides/family-rides`} target={'_blank'}>{t('family_rides')}</a>
                  },
                  {
                    key: 2,
                    label: <a href={`${CMS_BASE_URL}/rides/children-rides`}
                              target={'_blank'}>{t('children_rides')}</a>
                  },
                  {
                    key: 3,
                    label: <a href={`${CMS_BASE_URL}/rides/thrill-rides`}
                              target={'_blank'}>{t('thrill_rides')}</a>
                  }
                ]}
              />
            } trigger={['hover']}>
            
            <Button
              style={{ marginRight: 0 }}
              className={'header-button'}
              icon={<RiseOutlined style={{ color: '#00a4e7' }}/>}
              type="text">
              {t('cyan_rides')}
            </Button>
          </Dropdown>
        </a>*/}
          <Dropdown
            overlay={
              <Menu
                direction={'ltr'}
                items={[
                  { key: 1, label: <a href={`${CMS_BASE_URL}/dress-code-men`} target={'_blank'}>{t('men')}</a> },
                  {
                    key: 2,
                    label: <a href={`${CMS_BASE_URL}/dress-code-women`} target={'_blank'}>{t('women')}</a>
                  },
                  {
                    key: 3,
                    label: <a href={`${CMS_BASE_URL}/dress-code-kids`} target={'_blank'}>{t('kids')}</a>
                  }
                ]}
              />
            } trigger={['hover']}>
            <Button
              style={{ marginRight: 0 }}
              className={'header-button'}
              icon={<img style={{ marginRight: 10, height: 30, marginLeft: 5, marginTop: -5 }}
                         src={require('./../../assets/dress_icon.svg').default}
                         alt='user'/>}
              type="text">
              {t('dress_code')}
            </Button>
          </Dropdown>
        {currentUser && currentUser.user && <Button
          onClick={() => {
            navigate('/billing')
          }}
          style={{ marginRight: 0 }}
          className={'header-button'}
          type="text"
          icon={<img style={{ marginRight: 10, height: 20, marginLeft: 5 }} src={require('./../../assets/invoice.png')}
                     alt='user'/>}>
          {t('billing')}
        </Button>}
        {currentUser && currentUser.user && <Button
          onClick={() => {
            navigate('/myaccount')
          }}
          style={{ marginRight: 0 }}
          className={'header-button'}
          type="text"
          icon={<img style={{ marginRight: 10, height: 20, marginLeft: 5 }}
                     src={require('./../../assets/user_icon.svg').default}
                     alt='user'/>}>
          {t('update_profile')}
        </Button>}
        {currentUser && currentUser.access_token && <Button
          onClick={async () => {
            await dispatch(logout(currentUser.access_token))
            await dispatch(resetAllData())
            navigate('/')
            window.location.reload();
          }}
          className={'header-button'}
          type="text"
          icon={<img style={{ marginRight: 10, height: 20, marginLeft: 5 }}
                     src={require('./../../assets/logout_icon.svg').default}
                     alt='logout'/>}>
          {t('signout')}
        </Button>}
        <Select
          defaultValue={language}
          className={'language-select'}
          onChange={(value, option) => {
            i18n.changeLanguage(value).then(() => {
              window.location.reload();
            })
          }}
        >
          <Option value="en">English</Option>
          <Option value="ar">عربي</Option>
        </Select>
      </div>
      }
      <Image
        width={'100%'}
        preview={false}
        src={require('./../../assets/header-bg.webp')}>
      </Image>
      <img
        onClick={() => navigate('/')}
        className="logo" src={require('./../../assets/cyan_logo_white.png')} alt='logo'/>
      <img className="tickets-logo" src={require('./../../assets/tickets_logo.svg').default} alt='logo'/>
      {!hideTickets && <Button
        onClick={() => {
          if (currentUser && currentUser.access_token)
            navigate('/mytickets')
          else
            navigate('/otp/login')
        }}
        className="book-now my-tickets"
        size={"large"}
        type="primary">{t('my_tickets')}</Button>}
    </Header>
  )
}

export default AppHeader
