import IAction from "../../interfaces/IAction";
import * as CONSTANTS from "./core.constants";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import axios from "axios";
import { geLogoutUrl, geTranslationUrl, getSystemStatusUrl } from "../../constants/urls";
import { SystemStatusResponse, TranslationResponse } from "./core.types";

export const checkSystemStatusSuccess = (isLive: number): IAction => ({
  type: CONSTANTS.SYSTEM_STATUS_SUCCESS,
  payload: { isLive: isLive },
})
export const checkSystemStatusRequest = (): IAction => ({
  type: CONSTANTS.SYSTEM_STATUS_REQUEST
})
export const checkSystemStatusFailure = (): IAction => ({
  type: CONSTANTS.SYSTEM_STATUS_FAILURE
})
export const getTranslationSuccess = (en: object, ar: object): IAction => ({
  type: CONSTANTS.GET_TRANSLATION_SUCCESS,
  payload: { enTranslation: en, arTranslation: ar },
})
export const getTranslationRequest = (): IAction => ({
  type: CONSTANTS.GET_TRANSLATION_REQUEST
})
export const getTranslationFailure = (): IAction => ({
  type: CONSTANTS.GET_TRANSLATION_FAILURE
})

export const logoutSuccess = (): IAction => ({
  type: CONSTANTS.LOGOUT_SUCCESS,
})
export const logoutRequest = (): IAction => ({
  type: CONSTANTS.LOGOUT_REQUEST
})
export const logoutFailure = (): IAction => ({
  type: CONSTANTS.LOGOUT_FAILURE
})

export const checkSystemStatus = (): ThunkAction<void, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<void> => {
  try {
    dispatch(checkSystemStatusRequest())
    const response = await axios.get<SystemStatusResponse>(getSystemStatusUrl())
    dispatch(checkSystemStatusSuccess(response.data.data.is_live))
  } catch (error) {
    dispatch(checkSystemStatusFailure())
  }
}
export const getTranslations = (): ThunkAction<void, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<void> => {
  try {
    dispatch(getTranslationRequest())
    const engResponse = await axios.post<TranslationResponse>(geTranslationUrl(), { "language": "en" })
    const arResponse = await axios.post<TranslationResponse>(geTranslationUrl(), { "language": "ar" })
    dispatch(getTranslationSuccess(engResponse.data.data.language_label, arResponse.data.data.language_label))
  } catch (error) {
    dispatch(getTranslationFailure())
  }
}

export const logout = (token: string): ThunkAction<void, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<boolean> => {
  return new Promise(async resolve => {
    try {
      dispatch(logoutRequest())
      await axios.get<TranslationResponse>(geLogoutUrl(), {
        headers: {Authorization: `Bearer ${token}`}
      })
      dispatch(logoutSuccess())
      resolve(true)
    } catch (error) {
      dispatch(logoutFailure())
      resolve(false)
    }
  })
}
