import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { Button, Card, Checkbox, Col, Form, Image, Input, Layout, message, Modal, Radio, Row, Table } from "antd";
import { useReduxDispatch } from "../../../helpers";
import { useSelector } from "react-redux";
import { getBookings, getCurrentUser, getLoading, getPhone, getSelectedDate } from "../booking.selectors";
import { ColumnsType } from "antd/es/table";
import { BookingInfo, CouponData } from "../booking.types";
import HeaderSteps from "../../../components/steps";
import { calculateCoupon, changePhone, removeCoupon, resetAllData, verifyOtp } from "../booking.actions";
import { useNavigate } from "react-router-dom";
//@ts-ignore
import PDFViewer from 'pdf-viewer-reactjs'
import { useTranslation } from "react-i18next";
import FooterCom from './../../../components/Footer'
import AppHeader from "../../../components/Header";
import { logout } from "../../core/core.actions";
import Men from 'src/components/Dresscode/Men';
import Women from 'src/components/Dresscode/Women';
import Kids from 'src/components/Dresscode/Kids';
import { MAIN_WEBSITE_URL } from "../../../constants/urls";
import { CloseCircleOutlined } from "@ant-design/icons";

const { Header, Footer, Content } = Layout;

export default function ProfileContainer(): ReactElement {
  const dispatch = useReduxDispatch()
  const navigate = useNavigate()
  const loading = useSelector(getLoading)
  const bookings = useSelector(getBookings)
  const currentUser = useSelector(getCurrentUser)
  const selectedDate = useSelector(getSelectedDate)
  const phone = useSelector(getPhone)
  const [termsAccepted, setTermsAccepted] = useState<boolean | null>(null)
  const [showDressModal, setDressModal] = useState(false)
  const [couponCode, setCouponCode] = useState("")
  const [couponData, setCouponData] = useState<CouponData | null>(null)
  const headerRef = useRef();
  const { t } = useTranslation();
  const language = localStorage.getItem("i18nextLng") || "en";
  const isArabic = language === 'ar'
  const CMS_BASE_URL = `${MAIN_WEBSITE_URL}/${isArabic? 'ar':'en'}`

  useEffect(() => {
    if (!selectedDate)
      navigate('/')
  }, [selectedDate])

  const onGoBack = () => {
    navigate('/')
  }

  useEffect(() => {
    if (headerRef) {
      //@ts-ignore
      headerRef.current.scrollIntoView({ behavior: 'smooth' })
    }
    //setDressModal(true)
  }, [])


  const onRemoveCoupon = async () => {
    setCouponData(null);
    setCouponCode('')
    dispatch(removeCoupon())
  }

  const onCalculateCoupon = async (value: string) => {
    const sum = bookings.reduce((accumulator: any, object: BookingInfo) => {
      return accumulator + (object.price * object.quantity);
    }, 0)
    const tickets = bookings.reduce((accumulator: any, object: BookingInfo) => {
      return accumulator + object.quantity;
    }, 0)
    const data = {
      sub_total: sum,
      total_tickets: tickets,
      selected_date: selectedDate,
      line_items: [],
      coupon_code: value
    }
    bookings.forEach((booking) => {
      data.line_items.push({
        //@ts-ignore
        ticket_id: booking.id, quantity: booking.quantity
      })
    })
    //@ts-ignore
    dispatch(calculateCoupon(data, currentUser?.access_token)).then((data:CouponData)=>{
      if(data.success){
        setCouponData(data)
      }
      else {
        message.error(data?.message)
      }
    }).catch((e: any)=>{
      message.error(e);
    })
  }

  const onPurchase = async () => {
    /*const sum = bookings.reduce((accumulator: any, object: BookingInfo) => {
      return accumulator + (object.price * object.quantity);
    }, 0)
    // @ts-ignore
    const init = await dispatch(getCheckoutID(sum, currentUser?.access_token))
    // @ts-ignore
    if (init)*/
    if(termsAccepted)
      navigate('/checkout')
    else {
      setTermsAccepted(false)
      message.error("Please Check  Terms and Conditions");
    }
  }

  const columns: ColumnsType<BookingInfo> = [
    {
      title: t('ticket'),
      dataIndex: 'type',
      key: 'type',
      render: (text, item) => <span>{isArabic ? item.typeAr : item.type}</span>,
    },
    {
      title: t('quantity'),
      dataIndex: 'quantity',
      key: 'quantity',
      render: (text, item) => <span style={{display: 'block', textAlign: 'center'}}>{text}</span>,
    },
    {
      title: t('Price'),
      dataIndex: 'price',
      key: 'price',
      render: (text, item) => <span>{text} SR</span>,
    },
    {
      title: t('amount'),
      dataIndex: 'price',
      key: 'amount',
      render: (text, item) => <span>{item.price * item.quantity} SR</span>,
    },
  ]

  return (
    <>
      <Layout
        //@ts-ignore
        ref={headerRef}>
        <AppHeader/>
        <Content className="body-container">
          <div className={'app-container'}>
            <Row justify={'center'}>
              <Col span={21}>
                <HeaderSteps current={2}/>
              </Col>
              <Col xs={22} md={18} lg={15}>
                <Card
                  type="inner"
                  className="mt-50 pb-20"
                  style={{ minHeight: 300 }}>
                  <Row align={'middle'} justify={'center'} className={'mt-20'}>
                    <Col span={22}><h3 style={{ color: '#00A4E7' }}>{`${t('welcome')} `}<span
                      style={{ color: '#003B87', fontSize: 22 }}>{currentUser?.user?.first_name}</span></h3></Col>
                    <Col span={22} style={{ marginTop: 30 }}>
                      <span>{`${t('your_mobile')} ${phone}, ${t('not_this')}`}</span>
                      <Button
                        type="link"
                        onClick={async () => {
                          //@ts-ignore
                          await dispatch(logout(currentUser.access_token))
                          await dispatch(changePhone())
                          navigate('/otp')
                        }}
                        style={{ marginLeft: -10 }}>{t('click_to_change')}</Button>
                    </Col>
                    <Col span={22} className={'mt-10'}>
                      <Table
                        size={'small'}
                        columns={columns}
                        dataSource={bookings}
                        pagination={false}
                        footer={() => {
                          const sum = bookings.reduce((accumulator: any, object: BookingInfo) => {
                            return accumulator + (object.price * object.quantity);
                          }, 0);
                          return (
                            <div>
                              {!couponData && <Row justify={'space-between'} className={'ml-5 mr-20'}>
                                <Col xs={24} md={8}>
                                  <Input
                                    className="coupon-code"
                                    bordered={true}
                                    onChange={(e)=>setCouponCode(e.target.value)}
                                    placeholder={'Enter Coupon Code'}
                                  />
                                </Col>
                                <Col xs={24} md={6} style={{textAlign:'right'}}>
                                  <Button
                                    disabled={!couponCode}
                                    className="get-code"
                                    loading={loading}
                                    onClick={()=>{onCalculateCoupon(couponCode)}}
                                    type="primary">{`${t('validate')}`}</Button>
                                </Col>
                              </Row>}
                              {
                                couponData && couponData?.success &&
                                <Row justify={'space-between'} className={'ml-5 mr-20'}>
                                  <Col xs={8}>
                                    <span className={'font-size-18 font-weight-300'}>{`Coupon (${couponCode})`}</span>
                                  </Col>
                                  <Col xs={10}>
                                    <span className={'font-size-20 font-weight-300 float-right'}>{couponData?.couponDescription}</span>
                                  </Col>
                                  <Col xs={6} className={'flex flex-justify-end flex-align-center'}>
                                    <CloseCircleOutlined
                                      onClick={()=>{
                                        onRemoveCoupon()
                                      }}
                                      style={{ fontSize: 20, cursor: 'pointer' }} />
                                  </Col>
                                </Row>
                              }
                              <hr style={{margin:10}}/>
                              <Row justify={'space-between'} className={'ml-5 mr-20'}>
                                <span className={'font-size-20 font-weight-300'}>{t('total_with_vat')}</span>
                                <div>
                                  <span className={`font-size-20 font-weight-700 ${couponData ? 'line-through':''}`}>{sum} SR</span>
                                  {couponData && <span className={`font-size-20 font-weight-700 ml-10`}>{couponData.totalAfterDiscounts} SR</span>}
                                </div>
                              </Row>
                            </div>
                          )
                        }}
                      />
                    </Col>
                    <Col span={22} style={{ marginTop: 10 }}>
                      <span>{`${t('ticket_valid_for')} ${selectedDate}`}</span>
                    </Col>
                    <Col span={22}>
                      <div className={'card-body no-padding  mt-30'}>
                        <div>
                          <Checkbox
                            className={termsAccepted === false ? 'term-box' : ''}
                            style={{ marginLeft: 20, marginTop: 20, marginRight: 20 }}
                            onChange={(e) => {
                              setTermsAccepted(e.target.checked)
                              /*if(e.target.checked)
                                setDressModal(true)*/
                            }}
                          >
                            <span>{`${t('have_read')} `}</span>
                            <a href={`${CMS_BASE_URL}/terms-and-conditions`} target={'_blank'}>{t('terms_conditions')}</a>
                            <span>{' , '}</span>
                            <a href={`${CMS_BASE_URL}/general-park-rules`} target={'_blank'}>{t('general_park_rules')}</a>
                            <span>{` ${t('and')} `}</span>
                            <a
                              href={'#'}
                              onClick={(e) => {
                                e.preventDefault()
                                setDressModal(true)
                              }}
                              target={'_blank'}>{t('dress_code')}
                            </a>
                          </Checkbox>
                        </div>
                        <Button
                          style={{ width: '94%', marginLeft: '3%', marginRight: '3%' }}
                          block
                          className="book-now mt-30"
                          size={"large"}
                          loading={loading}
                          disabled={termsAccepted === false}
                          htmlType="submit"
                          onClick={onPurchase}
                          type="primary">{t('complete_purchase')}</Button>
                        <Button
                          style={{ width: '94%', marginLeft: '3%', marginRight: '3%', backgroundColor: '#FFC42F' }}
                          block
                          className="book-now mt-30 mb-20"
                          size={"large"}
                          htmlType="submit"
                          onClick={onGoBack}
                          type="primary">{t('go_back')}</Button>
                      </div>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </div>
        </Content>
        <Footer className="footer-container" dir={'ltr'}>
          <FooterCom/>
        </Footer>
      </Layout>
      <Modal
        okText={'Accept'}
        closable={false}
        cancelButtonProps={{ style: { display: 'none' } }}
        bodyStyle={{ width: '100%', height: 500, padding: 0 }}
        onCancel={() => setDressModal(false)}
        onOk={() => setDressModal(false)}
        visible={showDressModal}>
        <iframe
          style={{ width: '100%', height: '100%' }}
          src={`${CMS_BASE_URL}/entry-conditions`}/>
      </Modal>
    </>
  )
}
