import { createSelector } from "reselect";
import { MODULE_NAME } from './booking.constants'
import { AuthData, Billing, Booking, BookingInfo, CouponData, IState, Ticket } from "./booking.types";

const selectState = (state: { [MODULE_NAME]: IState }): IState => state[MODULE_NAME]
export const getOtpSent = createSelector(selectState, (state: IState): boolean => state.otpSent)
export const getLoading = createSelector(selectState, (state: IState): boolean => state.loading)
export const getPhone = createSelector(selectState, (state: IState): string => state.phone)
export const getBookings = createSelector(selectState, (state: IState): BookingInfo[] => state.bookings)
export const getTickets = createSelector(selectState, (state: IState): Ticket[] => state.tickets)
export const getCurrentUser = createSelector(selectState, (state: IState): AuthData | null => state.currentUser)
export const getIsVerified = createSelector(selectState, (state: IState): boolean => state.verified)
export const getCalender = createSelector(selectState, (state: IState): object => state.monthDates)
export const getSpecialDays = createSelector(selectState, (state: IState): object => state.specialMonthDates)
export const getCheckoutId = createSelector(selectState, (state: IState): string => state.checkoutId)
export const getType = createSelector(selectState, (state: IState): string => state.type)
export const getSelectedDate = createSelector(selectState, (state: IState): string => state.selectedDate)
export const getUserBookings = createSelector(selectState, (state: IState): Booking[] => state.userBookings)
export const getUserBillings = createSelector(selectState, (state: IState): Billing[] => state.userBillings)
//@ts-ignore
export const getAppliedCoupon = createSelector(selectState, (state: IState): CouponData => state.couponData)
export const getSessionId = createSelector(selectState, (state: IState): string => state.sessionId)

