import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { Button, Card, Col, DatePicker, DatePickerProps, Image, Layout, message, Modal, Row, Table } from "antd";
import { useNavigate, useParams } from 'react-router-dom';
import { MinusCircleFilled, PlusCircleFilled } from "@ant-design/icons";
import { RangePickerProps } from "antd/es/date-picker";
import { useReduxDispatch } from "../../../helpers";
import { getCalenderData, getTicketsList, saveBookingInfo } from "../booking.actions";
import { BookingInfo } from "../booking.types";
import { useSelector } from "react-redux";
import { getBookings, getCalender, getSelectedDate, getTickets, getSpecialDays } from "../booking.selectors";
import HeaderSteps from "../../../components/steps";
import { getSystemStatus } from "../../core/core.selectors";
import Offline from "../../../components/Offline";
import moment from "moment";
import FooterCom from './../../../components/Footer'
import { useTranslation } from "react-i18next";
import AppHeader from "../../../components/Header";
import Pincode from "../../../components/Pincode";
import i18n from "i18next";
import TabbyPromo from "../../../components/TabbyPromo";
import TamaraPromo from "../../../components/TamaraPromo";

const { Header, Footer, Content } = Layout;

export default function DashboardContainer(): ReactElement {
  const navigate = useNavigate()
  const dispatch = useReduxDispatch()
  const ticketsData = useSelector(getTickets)
  const isLive = useSelector(getSystemStatus)
  const calender = useSelector(getCalender)
  const specialDays = useSelector(getSpecialDays)
  const { t } = useTranslation();
  const [tickets, setTickets] = useState({})
  const selectedDate = useSelector(getSelectedDate)
  const bookings = useSelector(getBookings)
  const language = localStorage.getItem("i18nextLng") || "en";
  const [date, setDate] = useState<any>( moment())
  const [otpVerified, setOtpVerified] = useState(true)
  const [systemDown, setSystemDown] = useState(false)
  const [specialDay, setSpecialDay] = useState({ visible: false, title: '', description: '' , type: ''})
  const purchaseBtnRef = useRef();
  const { lang } = useParams();

  const isArabic = language === 'ar'
  useEffect(() => {
    if (lang && (lang === 'en' || lang === 'ar') && lang !== language) {
      i18n.changeLanguage(lang ).then(() => {
        window.location.reload();
      })
    }
  }, [lang])
  useEffect(() => {
    fetchData()
  }, [])


  console.log('ticketdata', ticketsData)

  const fetchFirstAvailableDate = ()=>{
    const _date = date.format('YYYY-MM-DD')
    //@ts-ignore
    const isDisabled =  !calender || !calender[_date] || calender[_date] === 'grey';
    if(isDisabled){
      let avaData: any = null;
      Object.keys(calender).forEach((key)=>{
        //@ts-ignore
        if(avaData === null && calender[key] !== 'grey'){
          avaData = key;
          setDate(moment(key, 'YYYY-MM-DD'))
          return;
        }
      })
    }
  }

  const fetchData = async () => {
    dispatch(getTicketsList())
    const hasData = await dispatch(getCalenderData())
    // @ts-ignore
    if (!hasData)
      setSystemDown(true)
    else {
      fetchFirstAvailableDate();
      if (selectedDate) {
        const _tickets = {}
        bookings.forEach((booking) => {
          //@ts-ignore
          _tickets[booking.type] = booking.quantity
        })
        setTickets(_tickets)
      }
    }
  }

  const onChange: DatePickerProps['onChange'] = (date, dateString) => {
    setDate(date)
    setTickets ({})
    const dateStr = date?.format('YYYY-MM-DD');
    //@ts-ignore
    if(specialDays && specialDays[dateStr]?.is_special){
      //@ts-ignore
      setSpecialDay({
        visible: true,
        //@ts-ignore
        title: isArabic ? specialDays[dateStr].arabic_title : specialDays[dateStr].title,
        //@ts-ignore
        description: isArabic ? specialDays[dateStr].arabic_description :  specialDays[dateStr].description,
        type:''})
      return;
    }
  };

  const disabledDate: RangePickerProps['disabledDate'] = current => {
    // Can not select days before today and today
    const date = current.format('YYYY-MM-DD')
    //@ts-ignore
    return !calender || !calender[date] || calender[date] === 'grey';

  };
  const getColor = (current: any) => {
    const date = current.format('YYYY-MM-DD');
    //@ts-ignore
    if(specialDays && specialDays[date] && specialDays[date].is_special){
      //@ts-ignore
      return specialDays[date].special_color;
    }
    //@ts-ignore
    else if (!calender || !calender[date] || calender[date] === 'grey') return '#CFD2CF'
    //@ts-ignore
    else if (calender[date] === 'dark_green') return '#228b22'
    //@ts-ignore
    else if (calender[date] === 'light_green') return '#4cbb17'
    //@ts-ignore
    else if (calender[date] === 'yellow') return '#E6B325'
  }

  const isFamilyDay = (current: any) => {
    const date = current.format('YYYY-MM-DD');
    //@ts-ignore
    if (specialDays && specialDays[date] && specialDays[date].is_special) {
      //@ts-ignore
      return true;
    }
  }

  const handleDateRange = (current: any) => {
    const style: React.CSSProperties = {};
    style.backgroundColor = getColor(current);
   /* if(isFamilyDay(current))
      style.backgroundImage = "url('family_icon.svg')"*/
    style.color = 'white'
    return (
      <div className="ant-picker-cell-inner" style={style}>
        {current.date()}
      </div>
    );
  }

  const onBookNow = () => {
    let total = 0
    const bookings: BookingInfo[] = []
    ticketsData.forEach((ticket) => {
      //@ts-ignore
      if (tickets[ticket.name]) {
        //@ts-ignore
        bookings.push({
          id: ticket.id,
          type: ticket.name,
          typeAr: ticket.name_arabic,
          price: ticket.price,
          description: ticket.description,
          //@ts-ignore
          quantity: tickets[ticket.name]
        })
        //@ts-ignore
        total += tickets[ticket.name]
      }
    })
    if(total<=20) {
      dispatch(saveBookingInfo(bookings, date.format('YYYY-MM-DD')))
      navigate('/otp')
    }
    else {
      message.error(t('maximum_tickets_allowed'))
    }
  }

  // @ts-ignore
  const TicketSelector = ({ ticket }) => {
    const type = ticket.name
    return (
      <Card className={"ticket-card"} dir={'ltr'}>
        <Row align={'middle'}>
          <Col xs={24} md={6}>
            <div className={`ticket-image ${language === 'ar' ? 'right-radius' : ''}`}>
              <Image
                preview={false}
                wrapperStyle={{ marginLeft: 0, marginTop: 0, marginBottom: 0 }}
                src={ticket.image}
              />
            </div>
          </Col>
          <Col xs={24} md={18}>
            <Row className={`ticket-details ${language === 'ar' ? 'left-radius' : ''}`}>
              <Col xs={16} className={'pl-20 pt-20'}>
                <Col xs={24}>
                  <span className={''}
                        style={{ color: 'white' }}>{`${isArabic ? ticket.name_arabic : ticket.name}`}</span>
                </Col>
                <Col xs={24} className={'pt-10 pb-10'}>
                  <span className={'pt-10'} style={{ color: '#00ADEE' }}>{t(`overview`)}</span>
                </Col>
                <Col xs={24}>
                  <div className={'ticket-description'}
                       style={isArabic ? {marginLeft:2} : {marginRight: 2}}
                       dangerouslySetInnerHTML={{ __html: isArabic ? ticket.description_arabic : ticket.description }}/>
                </Col>
              </Col>
              <Col xs={1} className={'pt-25'}>
                <div className={'separator'}></div>
              </Col>
              <Col xs={7}>
                <Row justify={'center'} align={'middle'} style={{ height: '70%', marginTop: '15%' }}>
                  <Col xs={24} className={'pt-20'} style={{ textAlign: 'center' }}>
                  <div
                    className={'font-weight-700'}
                    style={{
                      color: 'white',
                      textAlign: 'center'
                    }}>{`${ticket.price} ${t('sr')}`}</div>
                    {ticket.old_price >0 && <span
                      className={'font-weight-700'}
                      style={{
                        color: 'white',
                        textAlign: 'center',
                        textDecoration : "line-through"
                      }}>{`${ticket.old_price} ${t('sr')}`}</span>}
                  </Col>
                  <Col xs={24} className={'pt-20 pb-20'}>
                    <Row justify={'center'} align={'middle'}>
                      <Button
                        type={'link'}
                        onClick={(e) => {
                          e.stopPropagation()
                          if (!date) {
                            message.error('Please select a date first.');
                            return
                          }
                          // @ts-ignore
                          const count = tickets[type] > 0 ? tickets[type] - 1 : 0
                          const _tickets = { ...tickets }
                          // @ts-ignore
                          _tickets[type] = count
                          setTickets(_tickets)
                        }}
                        icon={<MinusCircleFilled className="icon-button" style={{ color: '#00A4E7' }}/>}
                      />
                      <span style={{ width: 30, textAlign: 'center', color: 'white' }}>
                      {// @ts-ignore
                        tickets[type] ? tickets[type] : 0
                      }
                    </span>
                      <Button
                        type={'link'}
                        onClick={(e) => {
                          e.stopPropagation()
                          if (!date) {
                            message.error('Please select a date first.');
                            return
                          }
                          // @ts-ignore
                          const count = tickets[type] ? tickets[type] + 1 : 1;
                          //@ts-ignore
                          const dateStr = date.format('YYYY-MM-DD');
                          //@ts-ignore
                          if(specialDays && specialDays[dateStr]?.is_special && count === 1){
                            //@ts-ignore
                            setSpecialDay({
                              visible: true,
                              //@ts-ignore
                              title: isArabic ? specialDays[dateStr].arabic_title : specialDays[dateStr].title,
                              //@ts-ignore
                              description: isArabic ? specialDays[dateStr].arabic_description :  specialDays[dateStr].description,
                              type})
                            return;
                          }
                          const _tickets = { ...tickets }
                          // @ts-ignore
                          _tickets[type] = count
                          setTickets(_tickets)
                        }}
                        icon={<PlusCircleFilled className="icon-button"/>}
                      />

                    </Row>
                  </Col>
                  <Col xs={24} style={{ textAlign: 'center' }}>
                  <span style={{ textAlign: 'center', color: 'white' }}>
                {
                  //@ts-ignore
                  `${tickets[type] ? (tickets[type] * ticket.price) : 0} ${t('sr')}`
                }
              </span>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
    )
  }

  //@ts-ignore
  const selectedTickets = ticketsData ? ticketsData.filter(ticket => tickets[ticket.name]).map((ticket) => {
    //@ts-ignore
    return ({
      type: ticket.name,
      type_ar: ticket.name_arabic,
      price: ticket.price,
      description: ticket.description,
      //@ts-ignore
      quantity: tickets[ticket.name]
    })
  }) : []

  /*useEffect(() => {
    const script = document.createElement('script');
    script.src = `http://checkout.tabby.ai/tabby-promo.js`;
    script.async = true;

    const total = selectedTickets.reduce((accumulator: any, object: any) => {
      return accumulator + (object.price * object.quantity);
    }, 0);

    document.body.appendChild(script);
    //@ts-ignore
    new TabbyCard({
      selector: '#TabbyPromo', // empty div for TabbyCard.
      currency: 'SAR', // required, currency of your product. AED|SAR|KWD|BHD|QAR only supported, with no spaces or lowercase.
      lang: language, // Optional, language of snippet and popups.
      price: total, // required, total price or the cart. 2 decimals max for AED|SAR|QAR and 3 decimals max for KWD|BHD.
      size: 'narrow', // required, can be also 'wide', depending on the width.
      theme: 'black', // required, can be also 'default'.
      header: false,// if a Payment method name present already.
      source: 'cart',
    });
  }, [selectedTickets]);*/

  const total = selectedTickets.reduce((accumulator: any, object: any) => {
    return accumulator + (object.price * object.quantity);
  }, 0);

  const columns = [
    {
      title: t('ticket'),
      dataIndex: 'type',
      key: 'type',
      render: (text: any, item: any) => <span
        className={'font-size-10'}>{`${isArabic ? item.type_ar : item.type}`}</span>,
    },
    {
      title: t('quantity'),
      dataIndex: 'quantity',
      key: 'quantity',
    },
    {
      title: t('amount'),
      dataIndex: 'price',
      key: 'amount',
      render: (text: any, item: any) => <span>{item.price * item.quantity} {t('sr')}</span>,
    },
  ]

  if (!otpVerified)
    return <Pincode onSuccess={() => setOtpVerified(true)}/>

  return (
    <Layout>
      {isLive && <>
        <AppHeader/>
        <Content className="body-container">
          <div className={'app-container mb-100'}>
            <Row justify={'center'}>
              <Col span={21}>
                <HeaderSteps current={0}/>
              </Col>
              <Col xs={22} md={18} lg={14}>
                <Card title={t("book_your_ticket")} type="inner" className="mt-50 pb-20" bordered={false}>
                  <Row>
                    <Col span={24}>
                      <DatePicker
                        allowClear={false}
                        showToday={false}
                        dateRender={handleDateRange}
                        disabledDate={disabledDate}
                        className='datepicker mt-25 mb-20'
                        placeholder={t('select_date')}
                        size={'large'}
                        defaultValue={date}
                        value={date}
                        suffixIcon={<img className="calender-icon"
                                         src={require('./../../../assets/calender_icon.svg').default} alt='logo'/>}
                        onChange={onChange}/>
                    </Col>
                    {
                      ticketsData && ticketsData.map((ticket) => (
                        <Col span={24} dir={'ltr'}>
                          <TicketSelector ticket={ticket}/>
                        </Col>
                      ))
                    }
                  </Row>
                </Card>
              </Col>
              <Col xs={22} md={18} lg={{ span: 8, offset: 1 }} className={'mt-50'}>
                <Table
                  size={'small'}
                  columns={columns}
                  dataSource={selectedTickets}
                  pagination={false}
                  locale={{ emptyText: t('no_ticket_selected') }}
                  footer={() => {
                    const sum = selectedTickets.reduce((accumulator: any, object: any) => {
                      return accumulator + (object.price * object.quantity);
                    }, 0);
                    return (
                      <Row justify={'space-between'} className={'ml-5 mr-20'}>
                        <span className={'font-size-20 font-weight-300'}>{t('total_with_vat')}</span>
                        <span className={'font-size-20 font-weight-700'}>{sum} {t('sr')}</span>
                      </Row>
                    )
                  }}
                />
                <Button
                  //@ts-ignore
                  ref={purchaseBtnRef}
                  disabled={!date || !selectedTickets || selectedTickets.length === 0}
                  onClick={onBookNow}
                  block
                  className="book-now"
                  size={"large"}
                  type="primary">{t('purchase_now')}</Button>
                <div style={{columnGap: 8, marginTop: 20}}>
                  <TamaraPromo price={total}/>
                  <div style={{marginTop: 20}}></div>
                  <TabbyPromo price={total}/>
                </div>
              </Col>
            </Row>
          </div>
        </Content>
        <Footer className="footer-container" dir={'ltr'}>
          <FooterCom/>
        </Footer>
        <Modal
          visible={systemDown}
          title="System down"
          closable={false}
          onOk={() => {
          }}
          footer={null}
        >
          <p>{'Gartner system is down, please try again in few minutes.'}</p>
        </Modal>
        <Modal
          visible={specialDay.visible}
          title={
            <div className="d-flex align-items-center py-2 font-weight-900 font-size-22" style={{color:'rgb(54,170,236)'}}>
              <img className="family-icon me-1" src={'family_icon.svg'}/>
              {specialDay.title}
            </div>
          }
          okText={t('book_ticket')}
          cancelText={t('cancel')}
          okButtonProps={{className : 'ok-button-type'}}
          onOk={() => {
            if(specialDay.type) {
              // @ts-ignore
              const count = tickets[specialDay.type] ? tickets[specialDay.type] + 1 : 1;
              const _tickets = { ...tickets }
              //@ts-ignore
              _tickets[specialDay.type] = count
              setTickets(_tickets)
            }
            setSpecialDay({visible: false, title: '', description: '', type: ''})
          }}
          onCancel={()=>{
            setSpecialDay({visible: false, title: '', description: '', type: ''})
          }}
        >
          <p>{specialDay?.description}</p>
        </Modal>
      </>
      }
      {!isLive && <Offline/>}
    </Layout>
  )
}
