import { Button, Modal } from "antd";
import React, { useState } from "react";

function TabbyPromo(props) {
  const language = localStorage.getItem("i18nextLng") || "en";
  const isArabic = language === 'ar';
  const [isModalOpen, setIsModalOpen] = useState(false);
  
  const showModal = () => {
    setIsModalOpen(true);
  };
  
  const handleOk = () => {
    setIsModalOpen(false);
  };
  
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      <div className="sc-8a38d207-0 promo-button">
        
        <div className="sc-8a38d207-1 fhYDVM"><span className="sc-8a38d207-2 cWkccj">{isArabic ? `أو 4 دفعات بدون فوائد ريال سعودى ${props.price/4}. ` : `or 4 interest-free payments of SAR ${props.price/4}.`}</span>
          <span
            onClick={(e)=>{
              e.stopPropagation();
              e.preventDefault();
              showModal()
            }}
            className="sc-8a38d207-3 learn-more">{isArabic ? `اعرف المزيد`:`Learn more`}</span>
        </div>
        <div className="sc-8a38d207-5 promo-button-icon-tabby ">
          <img src="/tabby_logo.svg"
               alt=""
               width="56px" height="24px" className="sc-92fbb12b-1 kkpbGz"/>
        </div>
      </div>
      <Modal
        width={600}
        bodyStyle={{height: 500, width: '100%'}}
        footer={null}
        centered
        onCancel={handleCancel}
        title={isArabic ? "أقساط شهرية ميسّرة" : "Easy Monthly Installments"}
        visible={isModalOpen}
        onOk={handleOk}>
        <iframe
          width={'100%'}
          height={'100%'}
          src={`https://checkout.tabby.ai/promos/product-page/installments/${ isArabic ? 'ar':'en'}/?price=${props.price}&currency=SAR&installmentsCount=4`}></iframe>
      </Modal>
    </>
  
  )
}

export default TabbyPromo;
