import React from "react";
import { Layout } from "antd";

const { Header, Footer, Content } = Layout;

function Splash() {
  return(
    <div className={'splash_container'}>
    <img className="splash-logo" src={require('./../../assets/cyan_logo_new.svg').default} alt='logo'/>
    </div>
  )
}
export default Splash
