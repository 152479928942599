function Offline() {
  return(
    <div className="offline-container">
      <div className="box">
        <div className="animation">
          <div className="one spin-one"></div>
          <div className="two spin-two"></div>
          <div className="three spin-one"></div>
        </div>
        <h1>Under maintenance</h1>
        <p>Website is under maintenance. We will be back soon</p>
      </div>
    </div>
  )
}
export default Offline
