import React from "react";
function footer() {
  return(
    <div className="swimming_dress_code">
      <div className="row sdc_head">
        <div className="col-md-6">
          <a href="/"><img src={require('./../../assets/cyan_logo.svg').default} style={{width: 180}} /></a>
        </div>
        <div className="col-md-6">
          <h1>
            <div>SWIMMING <br/> DRESS CODE</div>
            <span className="ar_text">قواعد لبس السباحة</span>
          </h1>
        </div>
      </div>
      <div className="container">
        <div className="dress_for">
          <img src={require('./../../assets/women.svg').default} style={{width: 170}}/>
            <p>WOMEN</p>
        </div>
        <div className="swimwear_guides">
          <h2>SWIMWEAR GUIDLINES</h2>
          <div className="dress_allowed">
            <img src={require('./../../assets/women-bottom.svg').default} style={{width: 140}} />
              <img src={require('./../../assets/women-top.svg').default} style={{height: 160}} />
          </div>
          <div className="dress_not_allowed">
            <div className="row justify-content-md-center">
              <div className="col-md-auto">
                <img src={require('./../../assets/abaya.svg').default}/>
                  <h4>
                    <div>ABAYA</div>
                    <span className="ar_text">عباية</span>
                  </h4>
              </div>
              <div className="col-md-auto">
                <img src={require('./../../assets/one-piece.svg').default}/>
                  <h4>
                    <div>ONE PIECE</div>
                    <span className="ar_text">مايوه</span>
                  </h4>
              </div>
              <div className="col-md-auto">
                <img src={require('./../../assets/bikini.svg').default} />
                  <h4>
                    <div>BIKINI</div>
                    <span className="ar_text">بيكيني</span>
                  </h4>
              </div>
              <div className="col-md-auto">
                <img src={require('./../../assets/see-through.svg').default} />
                  <h4>
                    <div>SEE THROUGH <br/> CLOTHES</div>
                    <span className="ar_text">ملابس شفافة</span>
                  </h4>
              </div>
            </div>
            <div className="row justify-content-md-center">
              <div className="col-md-auto">
                <img src={require('./../../assets/cotton-clothes.svg').default} />
                  <h4>
                    <div>COTTON <br/> CLOTHES</div>
                    <span className="ar_text">ملابس قطنية<br/>
                                او نسيج غير ملائم<br/> للسباحة</span>
                  </h4>
              </div>
              <div className="col-md-auto">
                <img src={require('./../../assets/jeans.svg').default} />
                  <h4>
                    <div>JEANS</div>
                    <span className="ar_text">جينز</span>
                  </h4>
              </div>
              <div className="col-md-auto">
                <img src={require('./../../assets/short.svg').default} />
                  <h4>
                    <div>SHORT</div>
                    <span className="ar_text">شورت</span>
                  </h4>
              </div>
              <div className="col-md-auto">
                <img src={require('./../../assets/accessories.svg').default} />
                  <h4>
                    <div>Accessoiries</div>
                    <span className="ar_text">اكسسوارات</span>
                  </h4>
              </div>
              <div className="col-md-auto">
                <img src={require('./../../assets/buttons.svg').default} />
                  <h4>
                    <div>STUDS/ZIPPER/<br/>BUTTONS</div>
                    <span className="ar_text">قطع معدنية</span>
                  </h4>
              </div>
            </div>
          </div>
        </div>
        <div className="row swim_notes">
          <div className="col-md-6">
            <p>To keep with our family-friendly environment, as well as for health and safety reasons, Cyan Waterpark
              enforces a dress code</p>
            <ul>
              <li>Length. Women’s swimwear should cover body skin. Top piece length reaches mid-thigh. Bottom piece
                reaches shin.
              </li>
              <li>Material. All swimwear should be made out of nontransparent water-friendly materials. Cotton, jeans,
                and other inappropriate materials are not allowed.
              </li>
              <li>Accessories. For safety reasons, all accessories are not allowed. Including those attached to the
                swimwear.
              </li>
            </ul>
          </div>
          <div className="col-md-6">
            <p className="ar_text">لأجل الحفاظ على أجواء مناسبة للعائلات ومن أجل صحتكم الشخصیة وسلامتكم ، تفرض سیان
              ووتربارك ارتداء ملابس مناسبة لتحقيق القواعد التالية</p>
            <ul>
              <li className="ar_text">الطول: يتوجب ارتداء ملابس طويلة ذو قطعتين لتغطية الجسم كاملا بحيث يصل الجزء العلوي
                منها إلى منتصف الفخذ بينما الجزء السفلي إلى نهاية الساق
              </li>
              <li className="ar_text">النوعية/الخامة: يتوجب ارتداء ملابس مناسبة للمياه بحيث لا تكون شفافة ولا ملتصقة.
                يمنع ارتداء ملابس قطنية أو جينز أو أي قماش لا يتناسب مع المياه
              </li>
              <li className="ar_text">الإكسسوارات: لأسباب صحية ولسلامتكم، لا يسمح بوضع أي اكسسوارات حتى لو كانت جزء من
                ملابس السباحة
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}
export default footer
