import React from "react";
import { Stepper } from 'react-form-stepper';
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getSelectedDate } from "../../modules/booking/booking.selectors";
import { useTranslation } from "react-i18next";
import { Steps } from "antd";
const { Step } = Steps;
const HeaderSteps = ({ current }) => {
  const selectedDate = useSelector(getSelectedDate)
  const { t } = useTranslation();
  const navigate = useNavigate()
  return (
    <Steps
      direction="horizontal"
      style={{ marginTop: 40 }}
      connectorStyleConfig={{ activeColor: '#d6006d', disabledColor: '#FFB4B4', size: 2 }}
      current={current}
      styleConfig={{ activeBgColor: '#d6006d', inactiveBgColor: '#FFB4B4' }}
    >
      <Step
        title={t("select_tickets")}
        onStepClick={() => {
          if (selectedDate && current > 0)
            navigate('/')
        }}
      />
      <Step
        title={t("verify_identity")}
        onClick={() => {
          if (selectedDate && current > 1)
            navigate('/otp')
        }}
      />
      <Step
        title={t("provide_information")}
        onClick={() => {
          if (selectedDate && current > 2)
            navigate('/summary')
        }}
      />
      <Step title={t("purchase_ticket")}/>
    </Steps>
  )
}
export default HeaderSteps
