import React, { ReactElement, useEffect } from 'react'
import { Button, Card, Col, Form, Image, Input, Layout, Radio, Row } from "antd";
import { useReduxDispatch } from "../../../helpers";
import { useSelector } from "react-redux";
import { getCurrentUser, getLoading, getPhone, getSelectedDate } from "../booking.selectors";
import { useForm } from "antd/es/form/Form";
import { updateProfile } from "../booking.actions";
import { useNavigate } from "react-router-dom";
import HeaderSteps from "../../../components/steps";
import FooterCom from './../../../components/Footer'
import AppHeader from "../../../components/Header";

const { Header, Footer, Content } = Layout;

export default function ProfileContainer(): ReactElement {
  const dispatch = useReduxDispatch()
  const navigate = useNavigate()
  const loading = useSelector(getLoading)
  const currentUser = useSelector(getCurrentUser)
  const phone = useSelector(getPhone)
  const selectedDate = useSelector(getSelectedDate)
  const [form] = useForm()
  const onFinish = async (values: any) => {
    // @ts-ignore
    const success = await dispatch(updateProfile(values, currentUser?.access_token))
    //@ts-ignore
    if(success) {
      if (window.location.href.includes('myaccount'))
        navigate('/')
      else
        navigate('/summary')
    }
  };

  /*useEffect(() => {
    if (currentUser && currentUser.user)
      navigate('/summary')

  }, [currentUser])*/

  useEffect(() => {
    form.setFieldsValue({ phone })
  }, [phone])
  useEffect(() => {
    if(currentUser && currentUser.user){
      const user = currentUser.user
      console.log('user', user)
      form.setFieldsValue({
        first_name: user.first_name,
        last_name: user.last_name,
        email: user.email,
        phone: user.phone,
        gender: user.gender?.toLowerCase()
      })
    }
  }, [currentUser])

  return (
    <Layout>
      <AppHeader/>
      <Content className="body-container">
        <div className={'app-container'}>
          <Row justify={'center'}>
            <Col span={21}>
              {(!currentUser || !currentUser.user) && <HeaderSteps current={2}/>}
            </Col>
            <Col xs={22} md={18} lg={14}>
              <Card
                title={window.location.href.includes('myaccount') ? 'Update Profile' : 'Create Profile'}
                type="inner"
                className="mt-50 pb-20"
                style={{ minHeight: 300 }}>
                <div className={'card-body mt-20'}>
                  <Row align={'middle'} justify={'center'} className={'mt-20'}>
                    <Col span={22}>
                      <Form
                        hideRequiredMark
                        layout={'vertical'}
                        form={form}
                        initialValues={{ remember: true }}
                        onFinish={onFinish}
                        autoComplete="off"
                      >
                        <Form.Item
                          label="First Name"
                          name="first_name"
                          rules={[{ required: true, message: 'Please input your first name.' }]}
                        >
                          <Input bordered={false}/>
                        </Form.Item>

                        <Form.Item
                          label="Last Name"
                          name="last_name"
                          rules={[{ required: true, message: 'Please input your last name.' }]}
                        >
                          <Input bordered={false}/>
                        </Form.Item>
                        <Form.Item
                          label="Email"
                          name="email"
                          rules={[{ required: true, message: 'Please input your email.' }]}
                        >
                          <Input bordered={false}/>
                        </Form.Item>
                        <Form.Item
                          label="Phone"
                          name="phone"
                          rules={[{ required: true, message: 'Please input your phone.' }]}
                        >
                          <Input bordered={false} disabled/>
                        </Form.Item>

                        <Form.Item
                          label="Gender"
                          name="gender"
                          className={'hide-border-bottom'}
                          rules={[{ required: false, message: 'Please select your gender' }]}
                        >
                          <Radio.Group
                            optionType="button"
                            buttonStyle="outline">
                            <Radio value="male"> Male </Radio>
                            <Radio value="female"> Female </Radio>
                          </Radio.Group>
                        </Form.Item>


                        <Form.Item className={'hide-border-bottom'} style={{ justifyContent: 'center' }}>
                          <Button
                            block
                            className="book-now"
                            size={"large"}
                            htmlType="submit"
                            type="primary">Save</Button>
                        </Form.Item>
                      </Form>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Col>
          </Row>
        </div>
      </Content>
      <Footer className="footer-container" dir={'ltr'}>
        <FooterCom/>
      </Footer>
    </Layout>
  )
}
