import { IState, IServerError } from './core.types'
import IAction from "src/interfaces/IAction"
import * as CONSTANTS from './core.constants'
import get from 'lodash/get'

export const initialState: IState = {
  isAuthenticated: false,
  showProgress: false,
  progress: 0,
  isLoggingOut: false,
  isLoggingIn: false,
  isUploading: false,
  isDownloading: false,
  settingsLoading: false,
  constantsLoading: false,
  fileUrls: [],
  uploadError: '',
  downloadError: '',
  fetchCurrenciesError: '',
  serverError: null,
  currentUser: {
    id: null,
    firstName: '',
    lastName: '',
    companyName: '',
    companyWebsite: '',
    phone: '',
    email: '',
    role: '',
    roleId: null,
    status: '',
    isBanned: '',
    profilePicture: '',
    isApproved: false,
    unreadNotificationCount: 0,
  },
  isLive: 1,
  enTranslation: {
    "cancel": "Cancel",
    "book_ticket": "Book Ticket",
    "select_tickets": "Select tickets",
    "verify_identity": "Verify identity",
    "provide_information": "Provide information",
    "purchase_ticket": "Purchase ticket",
    "book_your_ticket": "Book Your Ticket",
    "amount": "Amount",
    "quantity": "Quantity",
    "ticket": "Ticket",
    "total": "Total",
    "sr": "SR",
    "verify_phone_number": "Verify your Phone Number",
    "enter_your_phone_number": "Enter your mobile number",
    "Enter_verification_code": "Enter verification code",
    "get_code": "Get Code",
    "summary": "Summary",
    "date": "Date",
    "my_tickets": "My Tickets",
    "purchase_now": "Purchase Now",
    "status": "Status",
    "go_back": "Go Back",
    "complete_purchase": "Proceed to Payment",
    "payment_information": "Payment Information",
    "overview": "Overview",
    "select_date": "Select date",
    "no_ticket_selected":"No Ticket Selected",
    "update_profile":"My Account",
    "signout": "Sign Out",
    "view_ticket": "View Ticket",
    "total_with_vat": "Total (including VAT)",
    "billing":"Billing",
    "view_invoice": "View Invoice",
    "invoice_id":"Invoice ID",
    "dress_code":"Dress Code",
    "cyan_rides":"Cyan Rides",
    "general_park_rules":"General Park Rules",
    "welcome":"Welcome",
    "your_mobile":"Your mobile is",
    "not_this":"not this?",
    "click_to_change":"Click to change",
    "ticket_valid_for":"This ticket is valid for",
    "have_read":"I have read and accept the",
    "kids":"Kids",
    "women": "Women",
    "men":"Men",
    "terms_conditions":"Terms & Conditions",
    "privacy_policy":"Privacy Policy",
    "and": "and",
    "follow_us":"Follow Us",
    "family_rides":"family_rides",
    "children_rides":"Children Rides",
    "thrill_rides":"Thrill Rides",
    "validate":"Verify Coupon",
    "maximum_tickets_allowed":"Maximum 10 tickets are allowed you can buy more again"
  },
  arTranslation: {
    "cancel": "يلغي",
    "book_ticket": "حجز التذاكر",
    "select_tickets": "اختر التذاكر",
    "verify_identity": "تحقق من الهوية",
    "provide_information": "تقديم المعلومات",
    "purchase_ticket": "شراء تذكرة",
    "book_your_ticket": "احجز تذكرتك",
    "amount": "مقدار",
    "quantity": "كمية",
    "ticket": "تذكرة",
    "total": "المجموع",
    "sr": "ريال سعودى",
    "verify_phone_number": "اكد على رقم هاتفك او جوالك",
    "enter_your_phone_number": "أدخل رقم هاتفك",
    "Enter_verification_code": "أدخل رمز التحقق",
    "get_code": "الحصول على رمز",
    "summary": "ملخص",
    "date": "تاريخ",
    "my_tickets": "تذاكري",
    "purchase_now": "شراء الآن",
    "status": "حالة",
    "go_back": "عُد",
    "complete_purchase": "الشروع في دفع",
    "payment_information": "معلومات الدفع",
    "overview": "ملخص",
    "select_date": "حدد تاريخ",
    "no_ticket_selected":"لم يتم تحديد تذكرة",
    "update_profile":"حسابي",
    "signout": "خروج",
    "view_ticket":"عرض التذكرة",
    "total_with_vat": "الإجمالي (شاملاً ضريبة القيمة المضافة)",
    "billing":"الفواتير",
    "view_invoice": "عرض الفاتورة",
    "invoice_id": "هوية صوتية",
    "dress_code":"قواعد اللباس",
    "cyan_rides":"ركوب سيان",
    "general_park_rules": "قواعد الحديقة العامة",
    "welcome":"أهلا بك",
    "your_mobile":"هاتفك المحمول",
    "not_this": "ليس هذا؟",
    "click_to_change":"انقر للتغيير",
    "ticket_valid_for":"هذه التذكرة صالحة ل",
    "have_read":"قرأت وأقبل ب",
    "kids":"أطفال",
    "women": "النساء",
    "men":"رجال",
    "terms_conditions":"الأحكام والشروط",
    "privacy_policy":"سياسة الخصوصية",
    "and":"و",
    "follow_us":"تابعنا",
    "family_rides":"ألعاب جماعية",
    "children_rides":"ألعاب الصغار",
    "thrill_rides":"ألعاب الإثارة",
    "maximum_tickets_allowed":"يسمح بحد أقصى 10 تذاكر يمكنك شراء المزيد منها مرة أخرى"
  },
  loading: false
}

export default (state: IState = initialState, action: IAction): IState => {
  switch (action.type) {
    case CONSTANTS.RESET_PROGRESS:
      return { ...state, showProgress: initialState.showProgress, progress: initialState.progress }
    case CONSTANTS.SET_PROGRESS:
      return { ...state, showProgress: true, progress: get(action, 'payload.progress') }

    case CONSTANTS.LOGOUT_REQUEST:
      return { ...state, isLoggingOut: true }
    case CONSTANTS.LOGOUT_SUCCESS:
      return initialState
    case CONSTANTS.LOGOUT_FAILURE:
      return { ...state, isLoggingOut: false }

    case CONSTANTS.SET_SERVER_ERROR:
      return { ...state, serverError: action.payload as IServerError }
    case CONSTANTS.RESET_SERVER_ERROR:
      return { ...state, serverError: initialState.serverError }

    case CONSTANTS.UPLOAD_REQUEST:
      return { ...state, isUploading: true }
    case CONSTANTS.UPLOAD_SUCCESS:
      return { ...state, isUploading: false, fileUrls: [...state.fileUrls, ...get(action, 'payload.fileUrls')] }
    case CONSTANTS.UPLOAD_FAILURE:
      return { ...state, isUploading: false, uploadError: action.error }

    case CONSTANTS.GET_TRANSLATION_REQUEST:
      return { ...state, loading: true }
    case CONSTANTS.GET_TRANSLATION_FAILURE:
      return { ...state, loading: false }

    case CONSTANTS.DOWNLOAD_REQUEST:
      return { ...state, isDownloading: true }
    case CONSTANTS.DOWNLOAD_SUCCESS:
      return { ...state, isDownloading: false }
    case CONSTANTS.DOWNLOAD_FAILURE:
      return { ...state, isDownloading: false, downloadError: action.error }
    case CONSTANTS.SYSTEM_STATUS_SUCCESS:
      //@ts-ignore
      return { ...state, isLive: action.payload?.isLive }
    case CONSTANTS.GET_TRANSLATION_SUCCESS:
      //@ts-ignore
      return { ...state, loading: false, enTranslation: action.payload?.enTranslation, arTranslation: action.payload?.arTranslation }
    default:
      return state
  }
}
