import React, { useEffect, useMemo, useState } from 'react'
import { Router, Route, BrowserRouter, Routes } from 'react-router-dom'
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import {
  BookingContainer,
  OtpContainer,
  WelcomeContainer,
  ProfileContainer,
  PaymentContainer,
  SuccessContainer,
  MyTicketsContainer,
  BillingContainer,
  DresscodeContainer
} from '../modules/booking'
import { useReduxDispatch } from "../helpers";
import { checkSystemStatus, getTranslations } from "../modules/core/core.actions";
import { useSelector } from "react-redux";
import { getArTranslation, getEngTranslation } from "../modules/core/core.selectors";
import { getTranslationLoading } from "../modules/core/core.selectors";
import Splash from "../components/Splash";
import {
  getBookings,
  getCheckoutId,
  getCurrentUser,
  getSelectedDate,
  getType
} from "../modules/booking/booking.selectors";
import { checkPendingPayment } from "../modules/booking/booking.actions";

interface Props {
  children?: any
}

const AppRouter = ({}: Props) => {
  const dispatch = useReduxDispatch()
  const en = useSelector(getEngTranslation)
  const ar = useSelector(getArTranslation)
  const loading = useSelector(getTranslationLoading)
  const currentUser = useSelector(getCurrentUser)
  const checkoutId = useSelector(getCheckoutId)
  const checkoutType = useSelector(getType)
  const bookings = useSelector(getBookings)
  const selectedDate = useSelector(getSelectedDate)
  const [transInit, setTransInit] = useState(false)
  useEffect(() => {
    if (currentUser && currentUser?.access_token && checkoutId) {
      checkPaymentStatus()
    }
    dispatch(checkSystemStatus())
    dispatch(getTranslations())
  }, [])
  const checkPaymentStatus = async () => {
    console.log('checkoutType',checkoutType)
    setTimeout(() => {
      if (bookings && checkoutType !== 'tabby' && checkoutType !== 'tamara') {
        console.log('checkoutType status',checkoutType)
        //@ts-ignore
        dispatch(checkPendingPayment(checkoutId, currentUser?.access_token, checkoutType, bookings, selectedDate))
      }
    }, 1000*60*5)
  }
  useEffect(() => {
    //@ts-ignore
    i18n
      .use(Backend)
      .use(LanguageDetector)
      .use(initReactI18next)
      .init({
        resources: {
          en: { translation: en },
          ar: { translation: ar }
        },
        fallbackLng: ["en"],
        detection: { checkWhitelist: true },
        debug: false,
        whitelist: ['ar', 'en'],
        interpolation: {
          escapeValue: false
        }
      }).then(() => {
      setTransInit(true)
    });
  }, [])
  if (loading || !transInit)
    return <Splash/>
  return (
    <BrowserRouter>
      <Routes>
        <Route key={'root'} path={'/'} element={<BookingContainer/>}/>
        <Route key={'booking'} path={'/booking'} element={<BookingContainer/>}/>
        <Route key={'bookingLang'} path={'/booking/:lang'} element={<BookingContainer/>}/>
        <Route key={'otp'} path={'/otp'} element={<OtpContainer/>}/>
        <Route key={'otplogin'} path={'/otp/:login'} element={<OtpContainer/>}/>
        <Route key={'profile'} path={'/profile'} element={<ProfileContainer/>}/>
        <Route key={'myaccount'} path={'/myaccount'} element={<ProfileContainer/>}/>
        <Route key={'welcome'} path={'/summary'} element={<WelcomeContainer/>}/>
        <Route key={'paymentSelect'} path={'/checkout'} element={<PaymentContainer/>}/>
        <Route key={'checkout'} path={'/checkout/:type'} element={<PaymentContainer/>}/>
        <Route key={'successtype'} path={'/success/:type'} element={<SuccessContainer/>}/>
        <Route key={'success'} path={'/success'} element={<SuccessContainer/>}/>
        <Route key={'mytickets'} path={'/mytickets'} element={<MyTicketsContainer/>}/>
        <Route key={'billing'} path={'/billing'} element={<BillingContainer/>}/>
        <Route key={'dresscode'} path={'/dresscode/:type'} element={<DresscodeContainer/>}/>
      </Routes>
    </BrowserRouter>
  )
}

export default AppRouter
