import React, { ReactElement, useEffect } from 'react'
import { Button, Card, Col, Form, Image, Input, Layout, Radio, Row } from "antd";
import { useReduxDispatch } from "../../../helpers";
import { useSelector } from "react-redux";
import { getCurrentUser, getLoading, getPhone, getSelectedDate } from "../booking.selectors";
import { useForm } from "antd/es/form/Form";
import { updateProfile } from "../booking.actions";
import { useNavigate, useParams } from "react-router-dom";
import HeaderSteps from "../../../components/steps";
import FooterCom from './../../../components/Footer'
import AppHeader from "../../../components/Header";
import Men from 'src/components/Dresscode/Men';
import Women from 'src/components/Dresscode/Women';
import Kids from 'src/components/Dresscode/Kids';

const { Header, Footer, Content } = Layout;

export default function DresscodeContainer(): ReactElement {
  const dispatch = useReduxDispatch()
  const navigate = useNavigate()
  const loading = useSelector(getLoading)
  const currentUser = useSelector(getCurrentUser)
  const phone = useSelector(getPhone)
  const selectedDate = useSelector(getSelectedDate)
  const [form] = useForm()
  const { type } = useParams();
  /*useEffect(() => {
    if (currentUser && currentUser.user)
      navigate('/summary')

  }, [currentUser])*/

  useEffect(() => {
    form.setFieldsValue({ phone })
  }, [phone])
  useEffect(() => {
  }, [currentUser])

  return (<>
      {type === 'men' && <Men/>}
      {type === 'women' && <Women/>}
      {type === 'children' && <Kids/>}
    </>
  )
}
