import React from "react";
function footer() {
  return(
    <div className="swimming_dress_code for_kids">
      <div className="row sdc_head">
        <div className="col-md-6">
          <a href="/"><img src={require('./../../assets/cyan_logo.svg').default}  style={{width: 180}} /></a>
        </div>
        <div className="col-md-6">
          <h1>
            <div>SWIMMING <br/> DRESS CODE</div>
            <span className="ar_text">قواعد لبس السباحة</span>
          </h1>
        </div>
      </div>
      <div className="container">
        <div className="dress_for" style={{marginTop: -20}} >
          <img src={require('./../../assets/kids.svg').default} style={{width: 220}} />
            <p style={{paddingTop: 10}} >KIDS UNDER 1.2 METERS</p>
        </div>
        <div className="swimwear_guides">
          <h2>SWIMWEAR GUIDLINES</h2>
          <div className="dress_not_allowed men_dress_not_allowed">
            <div className="row justify-content-md-center">
              <div className="col-md-auto">
                <img src={require('./../../assets/kids-underwear.svg').default} />
                  <h4>
                    <div>UNDERWEAR</div>
                    <span className="ar_text">ملابس داخلية</span>
                  </h4>
              </div>
              <div className="col-md-auto">
                <img src={require('./../../assets/diaper.svg').default} />
                  <h4>
                    <div>DIAPERS</div>
                    <span className="ar_text">حفاظات</span>
                  </h4>
              </div>
              <div className="col-md-auto">
                <img src={require('./../../assets/swim-diaper.svg').default} style={{marginRight: -60, width: 234}} />
                  <h4 className="color-green">
                    <div>SWIM DAIPER</div>
                    <span className="ar_text">حفاظات السباحة</span>
                  </h4>
              </div>
            </div>
            <div className="row justify-content-md-center">
              <div className="col-md-auto">
                <img src={require('./../../assets/kids-bikini.svg').default} />
                  <h4>
                    <div>BIKINI</div>
                    <span className="ar_text">بيكيني</span>
                  </h4>
              </div>
              <div className="col-md-auto">
                <img src={require('./../../assets/men-cotton-clothes.svg').default} />
                  <h4>
                    <div>COTTON CLOTHES</div>
                    <span className="ar_text">ملابس قطنية<br/>
                                او نسيج غير ملائم<br/> للسباحة    </span>
                  </h4>
              </div>
              <div className="col-md-auto">
                <img src={require('./../../assets/life-jacket.svg').default}  style={{width: 234, marginRight: -60}} />
                  <h4 className="color-green">
                    <div>LIFE JACKETS</div>
                    <span className="ar_text">سترة نجاة</span>
                  </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default footer
