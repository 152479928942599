import React, { useEffect, useState } from "react";
import { PersistGate } from 'redux-persist/integration/react'
import { Provider } from 'react-redux'
import AppRouter from './router'
import { store, persistor } from "./store";
import { ConfigProvider } from "antd";
import arEg from 'antd/es/locale/ar_EG';
import Splash from "./components/Splash";

export default function () {
  const [isLoading, setLoading] = useState(false)
  const language = localStorage.getItem("i18nextLng") || "en";

  const fakeRequest = () => {
    return new Promise(resolve => setTimeout(() => resolve(true), 1500))
  }

  const hideLoader = async () => {
    await fakeRequest()
    const el = document.querySelector('.loader')
    if (el) {
      el.remove()
      setLoading(false)
    }
  }
  useEffect(() => {
    hideLoader()
  }, [])

  /*if (isLoading) {
    return <Splash/>
  }*/

  return (
    <Provider store={store}>
      <PersistGate loading={null} onBeforeLift={undefined} persistor={persistor}>
        <ConfigProvider direction={language === 'ar' ? 'rtl': 'ltr'}>
          <React.Fragment>
            <AppRouter/>
          </React.Fragment>
        </ConfigProvider>
      </PersistGate>
    </Provider>
  )
}
