import IAction from "../../interfaces/IAction";
import * as CONSTANTS from './booking.constants'
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import axios from "axios";
import {
  AuthData,
  Billing,
  BillingListResponse,
  Booking,
  BookingInfo,
  BookingListResponse,
  BookingParams,
  CalculateCouponResponse,
  CalenderResponse,
  CheckoutIdResponse,
  CouponData,
  IState,
  OtpSentResponse,
  OtpVerifyResponse,
  PaymentStatusResponse, ReserveCouponResponse,
  Ticket,
  TicketListResponse,
  UpdateProfileParams,
  UpdateProfileResponse,
  User
} from "./booking.types";
import {
  getBillingListUrl,
  getBookingListUrl,
  getBookingUrl,
  getCalculateCouponUrl,
  getCalenderUrl,
  getCheckoutIdUrl,
  getOtpSendUrl,
  getOtpVerifyUrl,
  getPaymentStatusUrl,
  getReleaseCouponUrl,
  getReserveCouponUrl, getReserveTabbyCouponUrl, getTabbyPaymentStatusUrl,
  getTicketListUrl,
  getUpdateProfileUrl, tabbyBookingConfirmUrl
} from "../../constants/urls";
import { message, notification } from "antd";
import history from "../../utils/history";
import { checkSystemStatusFailure } from "../core/core.actions";

export const sendOtpSuccess = (phone: string): IAction => ({
  type: CONSTANTS.SEND_OTP_SUCCESS,
  payload: { otpSent: true, phone },
})
export const sendOtpRequest = (): IAction => ({
  type: CONSTANTS.SEND_OTP_REQUEST
})
export const sendOtpFailure = (): IAction => ({
  type: CONSTANTS.SEND_OTP_FAILURE
})

export const verifyOtpSuccess = (currentUser: AuthData): IAction => ({
  type: CONSTANTS.VERIFY_OTP_SUCCESS,
  payload: { verified: true, currentUser },
})
export const verifyOtpRequest = (): IAction => ({
  type: CONSTANTS.VERIFY_OTP_REQUEST
})
export const verifyOtpFailure = (): IAction => ({
  type: CONSTANTS.VERIFY_OTP_FAILURE
})

export const getCalenderSuccess = (monthDates: object,specialMonthDates: object ): IAction => ({
  type: CONSTANTS.GET_CALENDER_SUCCESS,
  payload: { monthDates, specialMonthDates },
})
export const getCalenderRequest = (): IAction => ({
  type: CONSTANTS.GET_CALENDER_REQUEST
})
export const getCalenderFailure = (): IAction => ({
  type: CONSTANTS.GET_CALENDER_FAILURE
})

export const updateProfileSuccess = (user: User): IAction => ({
  type: CONSTANTS.UPDATE_PROFILE_SUCCESS,
  payload: { user },
})
export const updateProfileRequest = (): IAction => ({
  type: CONSTANTS.UPDATE_PROFILE_REQUEST
})
export const updateProfileFailure = (): IAction => ({
  type: CONSTANTS.UPDATE_PROFILE_FAILURE
})

export const ticketListSuccess = (tickets: Ticket[]): IAction => ({
  type: CONSTANTS.TICKET_LIST_SUCCESS,
  payload: { tickets },
})
export const ticketListRequest = (): IAction => ({
  type: CONSTANTS.TICKET_LIST_REQUEST
})
export const ticketListFailure = (error: any): IAction => ({
  type: CONSTANTS.TICKET_LIST_FAILURE
})

export const bookingListSuccess = (bookings: Booking[]): IAction => ({
  type: CONSTANTS.BOOKING_LIST_SUCCESS,
  payload: { bookings },
})

export const billingListSuccess = (billings: Billing[]): IAction => ({
  type: CONSTANTS.BILLING_LIST_SUCCESS,
  payload: { billings },
})

export const bookingListRequest = (): IAction => ({
  type: CONSTANTS.BOOKING_LIST_REQUEST
})
export const bookingListFailure = (error: any): IAction => ({
  type: CONSTANTS.BOOKING_LIST_FAILURE
})

export const checkoutIdSuccess = (checkoutId: string, type = 'MADA', sessionId: string): IAction => ({
  type: CONSTANTS.CHECKOUT_ID_SUCCESS,
  payload: { checkoutId, type, sessionId },
})
export const checkoutIdRequest = (): IAction => ({
  type: CONSTANTS.CHECKOUT_ID_REQUEST
})
export const checkoutIdFailure = (error: any): IAction => ({
  type: CONSTANTS.CHECKOUT_ID_FAILURE
})

export const startLoading = (): IAction => ({
  type: CONSTANTS.START_LOADING
})
export const stopLoading = (): IAction => ({
  type: CONSTANTS.STOP_LOADING
})

export const addBookingSuccess = (checkoutId: string): IAction => ({
  type: CONSTANTS.CHECKOUT_ID_SUCCESS,
  payload: { checkoutId },
})
export const addBookingRequest = (): IAction => ({
  type: CONSTANTS.CHECKOUT_ID_REQUEST
})
export const addBookingFailure = (error: any): IAction => ({
  type: CONSTANTS.CHECKOUT_ID_FAILURE
})

export const reserveCouponRequest = (): IAction => ({
  type: CONSTANTS.RESERVE_COUPON_REQUEST
})
export const reserveCouponFailure = (error: any): IAction => ({
  type: CONSTANTS.RESERVE_COUPON_FAILURE
})
export const reserveCouponSuccess = (couponData: CouponData): IAction => ({
  type: CONSTANTS.RESERVE_COUPON_SUCCESS,
  payload: { couponData },
})

export const calculateCouponSuccess = (couponData: CouponData): IAction => ({
  type: CONSTANTS.CALCULATE_COUPON_SUCCESS,
  payload: { couponData },
})

export const removeCouponRequest = (): IAction => ({
  type: CONSTANTS.REMOVE_COUPON_REQUEST,
  payload: { },
})


export const saveBookingInfoSuccess = (data: BookingInfo[], selectedDate: string): IAction => ({
  type: CONSTANTS.SAVE_BOOKING_INFO_SUCCESS,
  payload: { bookings: data, selectedDate },
})
export const saveBookingInfoRequest = (): IAction => ({
  type: CONSTANTS.SAVE_BOOKING_INFO_REQUEST
})
export const saveBookingInfoFailure = (error: any): IAction => ({
  type: CONSTANTS.SAVE_BOOKING_INFO_FAILURE
})
export const resetData = (): IAction => ({
  type: CONSTANTS.RESET_BOOKING_DATA
})
export const resetAll = (): IAction => ({
  type: CONSTANTS.RESET_ALL
})
export const resetSession = (): IAction => ({
  type: CONSTANTS.CHANGE_PHONE
})

export const sendOtp = (phone: string): ThunkAction<void, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<void> => {
  try {
    dispatch(sendOtpRequest())
    const response = await axios.post<OtpSentResponse>(getOtpSendUrl(), { phone })
    if (!!response.data.data.success) {
      message.error(response.data.data.message)
      dispatch(sendOtpFailure())
    } else {
      message.success('Code sent successfully')
      dispatch(sendOtpSuccess(phone))
    }
  } catch (error) {
    dispatch(sendOtpFailure())
  }
}

export const verifyOtp = (phone: string, otp: string): ThunkAction<void, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<void> => {
  try {
    dispatch(verifyOtpRequest())
    const response = await axios.post<OtpVerifyResponse>(getOtpVerifyUrl(), { phone, otp })
    console.log('response', response.data.data)
    if (response.data.data.verify === 0 || response.data.data.success === 'false') {
      //message.error(response.data.data.message || 'Wrong code. try again')
      notification.open({
        message: 'Authentication Failed',
        description: response.data.data.message || 'Wrong code. try again',
        placement: 'bottom',
        onClick: () => {
        },
      });
      dispatch(verifyOtpFailure())
    } else {
      message.success('Successfully verified')
      dispatch(verifyOtpSuccess(response.data.data))
    }

  } catch (error) {
    dispatch(verifyOtpFailure())
  }
}
export const updateProfile = (data: UpdateProfileParams, token: string): ThunkAction<void, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<boolean> => {
  return new Promise(async resolve => {
    try {
      dispatch(updateProfileRequest())
      const response = await axios.post<UpdateProfileResponse>(getUpdateProfileUrl(), data, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      console.log(response.data.data)
      if(response.data.data.success === 'false') {
        message.error('Email is already taken')
        resolve(false)
      }
      else {
        dispatch(updateProfileSuccess(response.data.data.user))
        resolve(true)
      }
    } catch (error) {
      console.log(error)
      dispatch(updateProfileFailure())
      resolve(false)
    }
  })
}

export const saveBookingInfo = (data: BookingInfo[], selectedDate: string): ThunkAction<void, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<void> => {
  try {
    dispatch(saveBookingInfoSuccess(data, selectedDate))
  } catch (error) {
    dispatch(saveBookingInfoFailure(error))
  }
}
export const getTicketsList = (): ThunkAction<void, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<void> => {
  try {
    dispatch(ticketListRequest())
    const response = await axios.get<TicketListResponse>(getTicketListUrl())
    dispatch(ticketListSuccess(response.data.data.tickets.data))
  } catch (error) {
    dispatch(ticketListFailure(error))
  }
}
export const getBookingList = (token: string): ThunkAction<void, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<void> => {
  try {
    dispatch(bookingListRequest())
    const response = await axios.get<BookingListResponse>(getBookingListUrl(), {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    dispatch(bookingListSuccess(response.data.data.ticketBookings.data))
  } catch (error) {
    dispatch(bookingListFailure(error))
  }
}
export const getBillingList = (token: string): ThunkAction<void, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<void> => {
  try {
    //dispatch(bookingListRequest())
    const response = await axios.get<BillingListResponse>(getBillingListUrl(), {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    dispatch(billingListSuccess(response.data.data.invoices.data))
  } catch (error) {
    dispatch(bookingListFailure(error))
  }
}
export const getCalenderData = (): ThunkAction<void, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<boolean> => {
  return new Promise(async resolve => {
    try {
      dispatch(getCalenderRequest())
      const response = await axios.get<CalenderResponse>(getCalenderUrl())
      if (!response.data.data.monthDates || Object.keys(response.data.data.monthDates).length === 0) {
        resolve(false)
      }
      else {
        const specialDays = {}
        response.data.data.specialMonthDates?.map((date)=>{
          //@ts-ignore
          specialDays[date.date] = date
        })
        dispatch(getCalenderSuccess(response.data.data.monthDates, specialDays))
        resolve(true)
      }
    } catch (error) {
      dispatch(getCalenderFailure())
    }
  })
}

export const getCheckoutID = (amount: number, token: string, type = 'MADA', bookingData: object, callback='', locale='en'): ThunkAction<void, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<string> => {
  return new Promise(async (resolve, reject) => {
    try {
      dispatch(checkoutIdRequest())
      let sessionId = null;
      //@ts-ignore
      if(bookingData && bookingData.coupon_code) {
        const url = getReserveCouponUrl();
        const couponResponse = await axios.post<ReserveCouponResponse>(url, bookingData, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        if(couponResponse.status === 200 && couponResponse.data.data.success){
          //@ts-ignore
          sessionId = couponResponse.data.data.sessionId.item
        }
      }
      const url = type === 'tabby' ||  type === 'tamara' ? getReserveTabbyCouponUrl(type) : getCheckoutIdUrl();
      const response = await axios.post<CheckoutIdResponse>(url, { amount, "type": type, booking_data: bookingData, callback, locale }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      if((type === 'tabby' || type === 'tamara') && response.data.data.success){
        console.log('url', response.data.data)
        //@ts-ignore
        dispatch(checkoutIdSuccess(response.data.data.url, type, sessionId))
        //@ts-ignore
        resolve(response.data.data.url)
      }
      else if(response.data.data.id) {
        dispatch(checkoutIdSuccess(response.data.data.id, type, sessionId))
        resolve(response.data.data.id)
      }
      else {
        reject(response.data.data.message)
      }
    } catch (error) {
      dispatch(checkoutIdFailure(error))
      resolve('')
    }
  })
}

export const getPaymentStatus = (id: string, token: string, type = 'MADA'): ThunkAction<void, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<any> => {
  return new Promise(async resolve => {
    try {
      dispatch(startLoading())
      const response = await axios.get<PaymentStatusResponse>(getPaymentStatusUrl(id, type), {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      console.log(response.data.data)
      dispatch(stopLoading())
      resolve(response.data.data)
    } catch (error: any) {
      dispatch(checkoutIdFailure(error))
      resolve(error.toString())
    }
  })
}

export const tabbyBookingConfirm = (id: string, token: string): ThunkAction<void, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<any> => {
  return new Promise(async resolve => {
    try {
      dispatch(startLoading())
      const response = await axios.get<PaymentStatusResponse>(tabbyBookingConfirmUrl('tabby')+`?payment_id=${id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      dispatch(stopLoading())
      resolve(response.data.data)
    } catch (error: any) {
      dispatch(checkoutIdFailure(error))
      resolve(error.toString())
    }
  })
}

export const getTabbyPaymentStatus = (id: string, token: string, type='tabby'): ThunkAction<void, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<any> => {
  return new Promise(async resolve => {
    try {
      dispatch(startLoading())
      const param = type === 'tabby' ? `payment_id=${id}` : `orderId=${id}`;
      const response = await axios.get<PaymentStatusResponse>(getTabbyPaymentStatusUrl(type)+`?${param}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })

      const confirmPayment = await axios.get<PaymentStatusResponse>(tabbyBookingConfirmUrl(type)+`?${param}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })

      dispatch(stopLoading())
      resolve(response.data.data)
    } catch (error: any) {
      dispatch(checkoutIdFailure(error))
      resolve(error.toString())
    }
  })
}

export const checkPendingPayment = (id: string, token: string, type = 'MADA', bookings: BookingInfo[], selectedDate: string): ThunkAction<void, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<void> => {
  try {
    const response = await axios.get<PaymentStatusResponse>(getPaymentStatusUrl(id, type), {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    const data = response.data.data
    if (data.success) {
      const sum = bookings.reduce((accumulator: any, object: BookingInfo) => {
        return accumulator + (object.price * object.quantity);
      }, 0)
      const tickets = bookings.reduce((accumulator: any, object: BookingInfo) => {
        return accumulator + object.quantity;
      }, 0)
      const data = {
        sub_total: sum,
        total_tickets: tickets,
        selected_date: selectedDate,
        payment_id: id,
        line_items: []
      }
      bookings.forEach((booking) => {
        data.line_items.push({
          //@ts-ignore
          ticket_id: booking.id, quantity: booking.quantity
        })
      })
      const bookingResponse = await axios.post<CheckoutIdResponse>(getBookingUrl(), data, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      dispatch(addBookingSuccess(bookingResponse.data.data.id))
      //dispatch(resetData())
    }
  } catch (error) {
    dispatch(checkSystemStatusFailure())
  }
}

export const addBooking = (booking: BookingParams, token: string): ThunkAction<void, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<boolean> => {
  return new Promise(async resolve => {
    try {
      dispatch(addBookingRequest())
      const response = await axios.post<CheckoutIdResponse>(getBookingUrl(), booking, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      //@ts-ignore
      if(booking.sessionId){
        try {
          //@ts-ignore
          const response = await axios.post(getReleaseCouponUrl(), { session_id: booking.sessionId }, {
            headers: {
              Authorization: `Bearer ${token}`
            }
          })
        }catch (e) {
          console.log(e)
        }
      }
      dispatch(addBookingSuccess(response.data.data.id))
      dispatch(resetData())
      resolve(true)
    } catch (error) {
      dispatch(addBookingFailure(error))
      resolve(false)
    }
  })
}

export const resetAllData = (): ThunkAction<void, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<void> => {
  console.log('reset all')
  await dispatch(resetData())
  await dispatch(resetAll())
}
export const resetBookingData = (): ThunkAction<void, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<void> => {
  await dispatch(resetData())
}

export const changePhone = (): ThunkAction<void, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<void> => {
  await dispatch(resetSession())
}

export const calculateCoupon = (bookingData: object, token: string): ThunkAction<void, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<CouponData> => {
  return new Promise(async (resolve, reject) => {
    try {
      dispatch(startLoading())
      const response = await axios.post<CalculateCouponResponse>(getCalculateCouponUrl(), bookingData, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      dispatch(stopLoading())
      if(response.data.data) {
        //@ts-ignore
        dispatch(calculateCouponSuccess({...response.data.data, coupon_code: bookingData.coupon_code}))
        resolve(response.data.data)
      }
      else {
        //@ts-ignore
        reject(response.data?.message)
      }
    } catch (error) {
      reject(error)
    }
  })
}

export const removeCoupon = (): ThunkAction<void, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<void> => {
  return new Promise(async (resolve, reject) => {
    dispatch(removeCouponRequest())
  })
}

/*export const reserveCoupon = (token: string, bookingData: object): ThunkAction<void, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<CouponData> => {
  return new Promise(async (resolve, reject) => {
    try {
      dispatch(reserveCouponRequest())
      const response = await axios.post<ReserveCouponResponse>(getReserveCouponUrl(), bookingData, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      if(response.data.data) {
        dispatch(reserveCouponSuccess(response.data.data))
        resolve(response.data.data)
      }
      else {
        //@ts-ignore
        reject(response.data.data.message)
      }
    } catch (error) {
      dispatch(reserveCouponFailure(error))
      reject(error)
    }
  })
}*/
