import React from "react";
import { useTranslation } from "react-i18next";
import { MAIN_WEBSITE_URL } from "../../constants/urls";
function Footer() {
  const { t } = useTranslation();
  const language = localStorage.getItem("i18nextLng") || "en";
  const isArabic = language === 'ar'
  const CMS_BASE_URL = `${MAIN_WEBSITE_URL}/${isArabic? 'ar':'en'}`
  return(
    <footer className="py-md-5 py-3 position-relative" id="footer-clip-path">
      <img id="footer-clip" className="position-absolute" src={require('./../../assets/footer-clip.png')} alt=""/>
        <div className="container mx-auto">
          <div className="row pb-md-5 pb-3 footer-row-border mx-0">
            <div className="col-md-3 text-md-start text-center mb-md-0 mb-3">
              <a target={'blank'} href="https://firebasestorage.googleapis.com/v0/b/cyanwaterpark-cwp.appspot.com/o/company_profil.pdf?alt=media&token=b75d225f-c46c-49ef-aa25-b554d3ec11f3">
                <img src={require('./../../assets/cyan_logo_white.png')} width="180" alt=""/>
              </a>
            </div>
            <div className="col-md-3">
              <ul className="navbar-nav">
                <li className="nav-item">
                  <a
                    className="nav-link text-white"
                    target={'_blank'}
                    href={`${CMS_BASE_URL}/terms-and-conditions`}>{t('terms_conditions')}</a>
                </li>
                <li className="nav-item">
                  <a
                    target={'_blank'}
                    className="nav-link text-white"
                    href={`${CMS_BASE_URL}/privacy-policy`}>{t('privacy_policy')}</a>
                </li>
              </ul>
            </div>
            <div className="col-md-3">
             {/* <ul className="navbar-nav">
                <li className="nav-item">
                  <a className="nav-link text-white" href="#">Contact Us</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link text-white" href="#">COVID-19 Updates</a>
                </li>
              </ul>*/}
            </div>
            <div className="col-md-3 text-md-start text-center">
              <span className="text-white pb-1 follow-us-border">{t('follow_us')}</span>
              <div className="social-icons mt-3 justify-content-md-start justify-content-center">
                <a className="text-decoration-none me-2" href="#">
                  <i className="fa-brands fs-6 text-white fa-instagram"></i>
                </a>
                <a className="text-decoration-none me-2" href="#">
                  <i className="fa-brands fs-6 text-white fa-facebook-f"></i>
                </a>
                <a className="text-decoration-none me-2" href="#">
                  <i className="fa-brands fs-6 text-white fa-twitter"></i>
                </a>
                <a className="text-decoration-none me-2" href="#">
                  <i className="fa-brands fs-6 text-white fa-snapchat"></i>
                </a>
              </div>
            </div>
          </div>
          <div className="text-md-start text-center text-white mt-4">
            &copy; COPYRIGHT CYAN WATERPARK 2022
          </div>
        </div>
    </footer>
  )
}
export default Footer
