import React, { ReactElement, useEffect, useState } from 'react'
import { Button, Card, Col, Form, Image, Input, Layout, Modal, Radio, Row } from "antd";
import { useReduxDispatch } from "../../../helpers";
import { useSelector } from "react-redux";
import {
  getBookings,
  getCheckoutId,
  getCurrentUser,
  getLoading,
  getPhone,
  getSelectedDate,
  getSessionId
} from "../booking.selectors";
import {
  addBooking,
  getCheckoutID,
  getPaymentStatus,
  getTabbyPaymentStatus,
  resetBookingData,
  updateProfile
} from "../booking.actions";
import { useNavigate, useParams } from "react-router-dom";
import HeaderSteps from "../../../components/steps";
import { CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons";
import { BookingInfo, LineNumberParams } from "../booking.types";
import FooterCom from './../../../components/Footer'
import AppHeader from "../../../components/Header";
import { useTranslation } from "react-i18next";
import Lottie from "lottie-react";
import { useSearchParams } from 'react-router-dom';
import SuccessAnimation from "./../../../assets/lottie/success.json";

const { Header, Footer, Content } = Layout;

export default function PaymentContainer(): ReactElement {
  const dispatch = useReduxDispatch()
  const navigate = useNavigate()
  const loading = useSelector(getLoading)
  const currentUser = useSelector(getCurrentUser)
  const checkoutId = useSelector(getCheckoutId)
  const bookings = useSelector(getBookings)
  const selectedDate = useSelector(getSelectedDate)
  const sessionId = useSelector(getSessionId)
  const [isCanceled, setCanceleds] = useState(false)
  const [isFailed, setFailed] = useState(false)
  const [status, setStatus] = useState('')
  const [description, setDescription] = useState('')
  const [showDressModal, setDressModal] = useState(false)
  const { t } = useTranslation();
  const { type } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const language = localStorage.getItem("i18nextLng") || "en";
  const isArabic = language === 'ar'

  const onGoToHome = () => {
    navigate('/')
  }
  const onGoToCheckout= () => {
    navigate(`/checkout`)
  }
  const onGoToMyTickets = () => {
    navigate('/mytickets')
  }
  useEffect(() => {
    const isCanceled= searchParams.get('cancel');
    const isFailed= searchParams.get('failure');
    console.log('failed', isFailed)
    if(isCanceled){
      setCanceleds(true)
      return;
    }
    if(isFailed){
      setFailed(true)
      return;
    }
    if(type === 'tabby' || type === 'tamara'){
      checkTabbyPaymentStatus(type);
    }else {
      checkPaymentStatus()
    }
  }, [])
  const checkPaymentStatus = async () => {
    // @ts-ignore
    const resp = await dispatch(getPaymentStatus(checkoutId, currentUser?.access_token, type === 'mada' ? 'MADA' : type === 'visa' ? 'VISA' :  'APPLEPAY'))
    //@ts-ignore
    setDescription(resp.description)
    // @ts-ignore
    if (resp.success) {
      setStatus('success')
    }
    else {
      setStatus('failed')
    }
  }
  const checkTabbyPaymentStatus = async (type: string) => {
    const paymentId = type === 'tabby'? searchParams.get('payment_id') : searchParams.get('orderId');
    // @ts-ignore
    const resp = await dispatch(getTabbyPaymentStatus(paymentId, currentUser?.access_token, type))
    //@ts-ignore
    setDescription(resp.description)
    // @ts-ignore
    if (resp.success) {
      setStatus('success')
    }
    else {
      setStatus('failed')
    }
  }

  useEffect(() => {
    if (status === 'success' &&  selectedDate ) {
      if(type === 'tabby' || type === 'tamara' ){
        dispatch(resetBookingData())
        return;
      }
      const sum = bookings.reduce((accumulator: any, object: BookingInfo) => {
        return accumulator + (object.price * object.quantity);
      }, 0)
      const tickets = bookings.reduce((accumulator: any, object: BookingInfo) => {
        return accumulator + object.quantity;
      }, 0)
      const data = {
        sub_total: sum,
        total_tickets: tickets,
        selected_date: selectedDate,
        payment_id: checkoutId,
        line_items: [],
        sessionId,
      }
      bookings.forEach((booking) => {
        data.line_items.push({
          //@ts-ignore
          ticket_id: booking.id, quantity: booking.quantity
        })
      })
      // @ts-ignore
      dispatch(addBooking(data, currentUser?.access_token))
    }
  }, [status])

  return (
    <>
      <Layout>
        <AppHeader/>
        <Content className="body-container">
          <div className={'app-container'}>
            <Row justify={'center'}>
              <Col span={21}>
                <HeaderSteps current={4}/>
              </Col>
              <Col xs={22} md={18} lg={14}>
                <Card
                  type="inner"
                  className="mt-50 pb-20"
                  style={{ minHeight: 300 }}>
                  <Row align={'middle'} justify={'center'} className={'mt-20'}>
                    <Col span={24} style={{ textAlign: 'center' }}>
                      <div style={{display:'inline-block'}}>
                        {status !== 'success' && (status !== '' || isCanceled || isFailed) && <CloseCircleFilled style={{ fontSize: 120, color: '#C6005B' }}/>}
                        {status === 'success' && <Lottie
                          style={{width:300}}
                          animationData={SuccessAnimation} loop={true}/>}
                      </div>
                      {isCanceled && <div style={{marginTop: 40, marginBottom: 100}}>
                        {!isArabic && <span>You aborted the payment. Please retry or choose another payment method. Go to<Button type="link" onClick={onGoToCheckout}>Checkout</Button></span>}
                        {isArabic && <span>لقد ألغيت الدفعة. فضلاً حاول مجددًا أو اختر طريقة دفع أخرى. اذهب إلى <Button type="link" onClick={onGoToCheckout}>الخروج</Button></span>}
                      </div>}
                        {isFailed && <div style={{marginTop: 40, marginBottom: 100}}>
                          {!isArabic && <span>Sorry, Tabby is unable to approve this purchase. Please use an alternative payment method for your order.</span>}
                          {isArabic && <span>نأسف، تابي غير قادرة على الموافقة على هذه العملية. الرجاء استخدام طريقة دفع أخرى.</span>}

                      </div>}

                      {status !== '' && <h1>{t(status)}</h1>}
                      {status === 'success' && <p className={'mt-30'}>Your payment is being processed.<br/>
                        Your tickets will be sent to your registered email/sms shortly!</p>}
                      {status !== 'success' && type !== 'tamara' && <p className={'mt-30'}>{description}</p>}
                      <Button type="link" onClick={onGoToHome}>Go to Home</Button> |
                      <Button type="link" onClick={() => setDressModal(true)}>Dress Code</Button> |
                      <Button type="link" onClick={onGoToHome}>Buy more tickets</Button> |
                      <Button type="link" onClick={onGoToMyTickets}>View My Tickets</Button>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </div>
        </Content>
        <Footer className="footer-container" dir={'ltr'}>
          <FooterCom/>
        </Footer>
      </Layout>
      <Modal
        okText={'Accept'}
        closable={false}
        cancelButtonProps={{style:{display: 'none'}}}
        width={'80%'} bodyStyle={{width: '90%', height: 500, marginLeft: '5%'}}
        onCancel={() => setDressModal(false)}
        onOk={()=>setDressModal(false)}
        visible={showDressModal}>
        <iframe
          style={{width:'100%', height: '100%'}}
          src={'./dresscode.pdf'}
        />
      </Modal>
    </>
  )
}
