import React from "react";
import { Layout, Select } from "antd";
import VerificationInput from "react-verification-input";

const { Header, Footer, Content } = Layout;
const { Option } = Select;

function Pincode({ onSuccess }) {

  return (
    <div className={'overlay'}>
      <VerificationInput
        length={4}
        placeholder={''}
        onChange={(value)=>{
          if(value === '4444')
            onSuccess()
        }}
      />
    </div>
  )
}

export default Pincode
