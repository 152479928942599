import React, { ReactElement, useEffect, useRef, useState } from 'react'
import {
  Button,
  Card,
  Col,
  DatePicker,
  DatePickerProps,
  Image,
  Input,
  InputNumber,
  Layout,
  message,
  Row,
  Spin
} from "antd";
import { PlusCircleTwoTone, MinusCircleTwoTone } from "@ant-design/icons";
import VerificationInput from "react-verification-input";
import PhoneInput from 'react-phone-input-2';
import { useReduxDispatch } from "../../../helpers";
import { useSelector } from "react-redux";
import { getCurrentUser, getIsVerified, getLoading, getOtpSent, getPhone, getSelectedDate } from "../booking.selectors";
import { sendOtp, verifyOtp } from "../booking.actions";
import { useNavigate, useParams } from "react-router-dom";
import HeaderSteps from "../../../components/steps";
import { useTranslation } from "react-i18next";
import es from 'react-phone-input-2/lang/es.json'
import FooterCom from './../../../components/Footer'
import AppHeader from "../../../components/Header";

const { Header, Footer, Sider, Content } = Layout;

export default function DashboardContainer(): ReactElement {
  const navigate = useNavigate()
  const dispatch = useReduxDispatch()
  const { t } = useTranslation();
  const otpSent = useSelector(getOtpSent)
  const verified = useSelector(getIsVerified)
  const loading = useSelector(getLoading)
  const [phone, setPhone] = useState('')
  const [otp, setOtp] = useState('')
  const currentUser = useSelector(getCurrentUser)
  const cusPhone = useSelector(getPhone)
  const headerRef = useRef();
  const { login } = useParams()

  const selectedDate = useSelector(getSelectedDate)
  useEffect(()=>{
    if(currentUser && currentUser.user && cusPhone){
      setPhone(`0${cusPhone}`)
    }
  },[])
  useEffect(() => {
    if (!login && !selectedDate)
      navigate('/')
  }, [selectedDate])

  useEffect(()=>{
    if(headerRef) {
      //@ts-ignore
      headerRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  },[])

  useEffect(() => {
    if (currentUser && currentUser.access_token) {
      if(login)
        navigate('/mytickets')
      else
        navigate( currentUser && currentUser.user && currentUser.user.first_name && currentUser.user.email  ? '/summary':'/summary')
    }
  }, [currentUser])


  const onGoBack = () => {
    navigate(-1)
  }

  const onGetCode = () => {
    dispatch(sendOtp(phone.substring(1)))
  }
  const onInputCode = async (value: string) => {
    setOtp(value)
    if (value.length === 4) {
      await dispatch(verifyOtp(phone.substring(1), value))
      setOtp('')
    }
  }

  return (
    <Layout
      //@ts-ignore
      ref={headerRef}>
      <AppHeader/>
      <Content className="body-container">
        <div className={'app-container'}>
          <Row justify={'center'}>
            <Col span={21}>
              <HeaderSteps current={1}/>
            </Col>
            <Col xs={22} md={20} lg={16} xl={14}>
              <Card
                title={t("verify_phone_number")}
                type="inner"
                className="mt-50 pb-20"
                style={{ minHeight: 300 }}>
                <div className={'card-body mt-20'}>
                  <Row align={'top'} justify={'center'} className={'mt-20 mb-20'}>
                    <Col xs={24} md={17} className={'mb-20'}>
                      <Row>
                        <Col xs={24} md={8}>
                          <span className={'font-weight-500'}>{t('enter_your_phone_number')}</span>
                        </Col>
                        <Col xs={24} md={14} dir={'ltr'}>
                          <Input
                            placeholder={'05xxxxxxxx'}
                            value={phone}
                            type={'number'}
                            maxLength={10}
                            disabled={!!(currentUser && currentUser.user && cusPhone)}
                            onChange={e => {
                              if(e.target.value.length<=10) {
                                setPhone(e.target.value)
                              }
                            }}
                          />
                          { phone && phone.length >0 && phone.length !== 10 && <span className="text-danger">{t('phone_length','10 digits are required')}</span>}
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={24} md={5}>
                      <Button
                        disabled={!phone || phone.length !== 10}
                        onClick={onGetCode}
                        className="get-code"
                        loading={loading}
                        type="primary">{`${otpSent ? t('get_code') : t('get_code')}`}</Button>
                    </Col>
                  </Row>
                  {otpSent && <Row align={'middle'} justify={'center'} className={'mt-50 mb-20'}>
                    <Col xs={24}>
                      <Row align={'bottom'} justify={'center'} className={'mt-20'}>
                        <Col xs={24} md={20}>
                          <Row>
                            <Col xs={24} md={8}>
                              <span className={'font-weight-500'}>{t('Enter_verification_code')}</span>
                            </Col>
                            <Col xs={24} md={12} dir={'ltr'}>
                              <VerificationInput
                                //@ts-ignore
                                style={{width: '100%'}}
                                value={otp}
                                length={4}
                                placeholder={''}
                                onChange={onInputCode}
                                autoFocus
                              />
                            </Col>
                          </Row>
                        </Col>
                        <Col xs={0} md={4}>
                        </Col>
                      </Row>
                    </Col>
                  </Row>}
                </div>
              </Card>
              <Row align={'middle'} justify={'center'} className={'mt-20 mb-60'}>
                <Button
                  style={{ backgroundColor:'#FFC42F'}}
                  block
                  className="book-now mt-30 mb-20"
                  size={"large"}
                  htmlType="submit"
                  onClick={onGoBack}
                  type="primary">Go Back</Button>
              </Row>
            </Col>
          </Row>
        </div>
      </Content>
      <Footer className="footer-container" dir={'ltr'}>
        <FooterCom/>
      </Footer>
    </Layout>
  )
}
