import { Button, Modal } from "antd";
import React, { useState } from "react";

function TabbyPromo(props) {
  const language = localStorage.getItem("i18nextLng") || "en";
  const isArabic = language === 'ar';
  const [isModalOpen, setIsModalOpen] = useState(false);
  
  const showModal = () => {
    setIsModalOpen(true);
  };
  
  const handleOk = () => {
    setIsModalOpen(false);
  };
  
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      <div className="sc-8a38d207-0 promo-button" >
        <div className="sc-8a38d207-5 promo-button-icon ">
          <img src={isArabic ? '/tamara_logo_ar.svg' : '/tamara_logo_en.svg'}
               alt=""
               width="56px" height="24px" className="sc-92fbb12b-1 kkpbGz"/>
        </div>
        <div className="sc-8a38d207-1 fhYDVM"><span className="sc-8a38d207-2 cWkccj">{isArabic ? ` مقسمة على 4 دفعات بقيمة ريال سعودي ${props.price}. لا فوائد. لا توجد رسوم متأخرة `:`Split in 4 payments of SAR ${props.price}. No interest. No late fees`}</span><span
          className="sc-8a38d207-3 learn-more" onClick={showModal}>{isArabic ? `اعرف المزيد`:`Learn more`}</span></div>
      </div>
      <Modal
        width={600}
        bodyStyle={{height: 500, width: '100%'}}
        footer={null}
        centered
        onCancel={handleCancel}
        title={isArabic ? "أقساط شهرية ميسّرة" : "Easy Monthly Installments"}
        visible={isModalOpen}
        onOk={handleOk}>
        <iframe
          width={'100%'}
          height={'100%'}
          src={`https://cdn-sandbox.tamara.co/widget-v2/tamara-widget.html?lang=${isArabic? 'ar' : 'en'}&public_key=5fdcc2cc-7b1b-4b5d-a299-fd2e5a7592ac&country=SA&amount=${props.price}`}></iframe>
      </Modal>
    </>
  
  )
}

export default TabbyPromo;
