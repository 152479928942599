import { createStore, Store, applyMiddleware, compose, combineReducers, Reducer } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'
import { persistStore, persistReducer, Persistor } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import {
  reducer as rootReducer,
  MODULE_NAME as CORE_MODULE_NAME,
  initialState as coreInitialState,
} from 'src/modules/core'

import {
  reducer as bookingReducer,
  MODULE_NAME as BOOKING_MODULE_NAME,
  initialState as bookingInitialState,
} from 'src/modules/booking'

const persistConfig = {
  key: 'root',
  storage: storage
}

const createRootReducer = (): Reducer => {
  return combineReducers({
    [CORE_MODULE_NAME]: rootReducer,
    [BOOKING_MODULE_NAME]: bookingReducer,
  })
}

const persistedReducer = persistReducer(persistConfig, createRootReducer())

export default (): { store: Store; persistor: Persistor } => {
  const composer: Function = process.env.NODE_ENV === 'development' ? composeWithDevTools : compose
  const store: Store = createStore(
    persistedReducer,
    {
      [CORE_MODULE_NAME]: coreInitialState,
    },
    composer(applyMiddleware(thunk))
  )
  const persistor = persistStore(store)

  return { store, persistor }
}
