export const MODULE_NAME = 'booking'
export enum TICKET_TYPES {
  ADULTS = 'Adults',
  KIDS = 'Kids',
  NANNY = 'Nanny',
  SENIOR = 'Senior'
}
export enum TICKET_DESCRIPTIONS {
  TICKET_TYPES = 'Adult 1.2m +',
  KIDS = 'Child 1.2m -',
  NANNY = 'Nanny',
  SENIOR = 'Senior 60+',
  SPECIAL_NEEDS = 'Special Needs+'
}
export const SEND_OTP_REQUEST = `${MODULE_NAME}/SEND_OTP_REQUEST`
export const SEND_OTP_SUCCESS = `${MODULE_NAME}/SEND_OTP_SUCCESS`
export const SEND_OTP_FAILURE = `${MODULE_NAME}/SEND_OTP_FAILURE`

export const VERIFY_OTP_REQUEST = `${MODULE_NAME}/VERIFY_OTP_REQUEST`
export const VERIFY_OTP_SUCCESS = `${MODULE_NAME}/VERIFY_OTP_SUCCESS`
export const VERIFY_OTP_FAILURE = `${MODULE_NAME}/VERIFY_OTP_FAILURE`

export const GET_CALENDER_REQUEST = `${MODULE_NAME}/GET_CALENDER_REQUEST`
export const GET_CALENDER_SUCCESS = `${MODULE_NAME}/GET_CALENDER_SUCCESS`
export const GET_CALENDER_FAILURE = `${MODULE_NAME}/GET_CALENDER_FAILURE`

export const UPDATE_PROFILE_REQUEST = `${MODULE_NAME}/UPDATE_PROFILE_REQUEST`
export const UPDATE_PROFILE_SUCCESS = `${MODULE_NAME}/UPDATE_PROFILE_SUCCESS`
export const UPDATE_PROFILE_FAILURE = `${MODULE_NAME}/UPDATE_PROFILE_FAILURE`

export const SAVE_BOOKING_INFO_REQUEST = `${MODULE_NAME}/SAVE_BOOKING_INFO_REQUEST`
export const SAVE_BOOKING_INFO_SUCCESS = `${MODULE_NAME}/SAVE_BOOKING_INFO_SUCCESS`
export const SAVE_BOOKING_INFO_FAILURE = `${MODULE_NAME}/SAVE_BOOKING_INFO_FAILURE`

export const TICKET_LIST_REQUEST = `${MODULE_NAME}/TICKET_LIST_REQUEST`
export const TICKET_LIST_SUCCESS = `${MODULE_NAME}/TICKET_LIST_SUCCESS`
export const TICKET_LIST_FAILURE = `${MODULE_NAME}/TICKET_LIST_FAILURE`

export const BOOKING_LIST_REQUEST = `${MODULE_NAME}/BOOKING_LIST_REQUEST`
export const BOOKING_LIST_SUCCESS = `${MODULE_NAME}/BOOKING_LIST_SUCCESS`
export const BOOKING_LIST_FAILURE = `${MODULE_NAME}/BOOKING_LIST_FAILURE`

export const CHECKOUT_ID_REQUEST = `${MODULE_NAME}/CHECKOUT_ID_REQUEST`
export const CHECKOUT_ID_SUCCESS = `${MODULE_NAME}/CHECKOUT_ID_SUCCESS`
export const CHECKOUT_ID_FAILURE = `${MODULE_NAME}/CHECKOUT_ID_FAILURE`

export const RESERVE_COUPON_REQUEST = `${MODULE_NAME}/RESERVE_COUPON_REQUEST`
export const RESERVE_COUPON_SUCCESS = `${MODULE_NAME}/RESERVE_COUPON_SUCCESS`
export const RESERVE_COUPON_FAILURE = `${MODULE_NAME}/RESERVE_COUPON_FAILURE`
export const CALCULATE_COUPON_SUCCESS = `${MODULE_NAME}/CALCULATE_COUPON_SUCCESS`

export const REMOVE_COUPON_REQUEST = `${MODULE_NAME}/REMOVE_COUPON_REQUEST`

export const START_LOADING = `${MODULE_NAME}/START_LOADING`
export const STOP_LOADING = `${MODULE_NAME}/STOP_LOADING`

export const ADD_BOOKING_REQUEST = `${MODULE_NAME}/ADD_BOOKING_REQUEST`
export const ADD_BOOKING_SUCCESS = `${MODULE_NAME}/ADD_BOOKING_SUCCESS`
export const ADD_BOOKING_FAILURE = `${MODULE_NAME}/ADD_BOOKING_FAILURE`

export const RESET_BOOKING_DATA = `${MODULE_NAME}/RESET_BOOKING_DATA`
export const RESET_ALL = `${MODULE_NAME}/RESET_ALL`
export const CHANGE_PHONE = `${MODULE_NAME}/CHANGE_PHONE`

export const BILLING_LIST_SUCCESS = `${MODULE_NAME}/BILLING_LIST_SUCCESS`
